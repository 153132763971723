import { ListGuesser, ShowGuesser, EditGuesser, Show, SimpleShowLayout, RichTextField, ImageField, TopToolbar, ListButton, CreateButton, EditButton, SimpleForm, TextInput, ReferenceInput, Create, BooleanField, FunctionField, useRefresh, useNotify, Confirm, useDataProvider, useRecordContext, useRedirect, Button, ReferenceManyField, TabbedShowLayout, ShowButton, TabbedForm, DateTimeInput, SelectInput, Edit, BooleanInput, required, NumberInput, NumberField, ArrayInput, SimpleFormIterator, DateInput, ReferenceArrayInput } from "react-admin";
import React, { useState } from 'react';
import { Datagrid, DateField, List, ReferenceField, TextField } from 'react-admin';
import AddMoreImage from "./imageInput";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import DocumentTab from "./documents/documentTab";
import { useLocation } from 'react-router-dom';
import CONSTANTS from "./Constants";
import PostAddIcon from '@mui/icons-material/PostAdd';
import CancelIcon from '@mui/icons-material/Cancel';
import { AddSubject, SubjectList } from "./subjects";
////////////////////////////////////// Create /////////////////////////////////////////
const ExamCreateToolbar = props => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
)
export const ExamsCreate = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const [classID, setClassID] = React.useState(query.get('class_id') && parseInt(query.get('class_id')));
    const [sessionID, setSessionID] = React.useState(query.get('session_id') && parseInt(query.get('session_id')));
    return <Create actions={<ExamCreateToolbar />} redirect="show">
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput source='class_id' reference='classes'>
                <SelectInput optionText='name' defaultValue={classID} onChange={e => setClassID(e.target.value)} />
            </ReferenceInput>
            <ReferenceInput source="session_id" reference="sessions" filter={{
                class_id: classID
            }}>
                <SelectInput optionText="name" defaultValue={sessionID} />
            </ReferenceInput>
            <DateInput source='start' validate={required()} />
            <DateInput source='end' validate={required()} />
            <ArrayInput source="subjects" validate={required()}>
                <SimpleFormIterator inline>
                    <TextInput source="name" helperText={false} />
                    <DateTimeInput source="start_at" validate={required()} />
                    <DateTimeInput source="end_at" validate={required()} />
                    <ReferenceInput reference="employees" source="teacher_id" validate={required()} />
                    <NumberInput source='full_marks' defaultValue={100} validate={required()} />
                </SimpleFormIterator>
            </ArrayInput>
            <AddMoreImage source="document_id" label="resources.documents.name" name='পরীক্ষাসংক্রান্ত নথিপত্র' />
        </SimpleForm>
    </Create>
}


////////////////////////////////////// Show /////////////////////////////////////////
const ExamTerminateButton = () => {
    const record = useRecordContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const [terminationIntent, setTerminationIntent] = React.useState(false);
    const [terminating, setTerminating] = React.useState(false);
    const dataProvider = useDataProvider();
    return terminationIntent ? (
        <Confirm
            title="Are you sure you want to terminate this exam?"
            content="This action is irreversible."
            onConfirm={async () => {
                setTerminating(true);
                dataProvider.update('exams', {
                    data: {
                        id: record.id,
                        end: new Date()
                    }
                }).then(({ data }) => {
                    setTerminating(false);
                    setTerminationIntent(false);
                    notify('resources.exams.messages.terminated_success', { type: 'success' });
                }).catch(err => {
                    setTerminating(false);
                    setTerminationIntent(false);
                    notify('resources.exams.messages.terminated_failed', { type: 'error' });
                }).finally(() => {
                    refresh();
                });
            }}
            onClose={() => {
                setTerminationIntent(false);
            }}
            open={true}
        />
    ) : (
        <Button
            label="resources.exams.action.terminate"
            onClick={e => {
                e.stopPropagation();
                setTerminationIntent(true);
            }}
            disabled={terminating}
            color="error"
        ><CancelIcon /></Button>

    )
}

const ResultAddButton = () => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const refresh = useRefresh();
    return (
        <Button
            label="resources.exams.action.add_result"
            onClick={e => {
                e.stopPropagation();
                redirect(`/exams/${record.id}/1`);
            }}
            color="primary"
            variant="contained"
        ><PostAddIcon /></Button>
    )
}
const ResultShowButton = () => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const refresh = useRefresh();
    return (
        <Button
            label="resources.exams.action.show_result"
            onClick={e => {
                e.stopPropagation();
                redirect(`/exams/${record.id}/show/1`);
            }}
            color="secondary"
            variant="contained"
        ><ReceiptLongIcon /></Button>
    )

};
const showExamAction = (exam, publishedComponent = <ResultShowButton />) => {
    if (!exam.end)
        return <ExamTerminateButton />;
    return null
};
const ExamShowToolbar = recordInit => {
    const record = useRecordContext() || recordInit;
    return (
        <TopToolbar>
            <ListButton />
            <EditButton />
            {record && showExamAction(record)}
        </TopToolbar>
    )
}
export const ExamsShow = () => (
    <Show actions={<ExamShowToolbar />}>
        <TabbedShowLayout>
            <TabbedShowLayout.Tab label="resources.exams.tabs.details">
                <TextField source="id" />
                <TextField source="name" />
                <DateField source="start"  {...CONSTANTS.dateOptions} />
            <DateField source="end"  {...CONSTANTS.dateOptions} />
                <ReferenceField source="class_id" reference="classes" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="session_id" reference="sessions" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <BooleanField source="result_published" />
                {/* <FunctionField label={false} render={showExamAction} /> */}
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="resources.exams.tabs.subjects">
                <ReferenceManyField reference="subjects" target="exam_id">
                    <Datagrid rowClick='show' bulkActionButtons={false}>
                        {/* <TextField source="id" /> */}
                        <TextField source="name" />
                        <NumberField source="full_marks" />
                        <DateField source="start_at" {...CONSTANTS.timeStampOptions} />
                        <DateField source="end_at" {...CONSTANTS.timeStampOptions} />
                        <ReferenceField source="teacher_id" reference="employees" link='show' />
                        <BooleanField source="result_submitted" />
                    </Datagrid>
                </ReferenceManyField>
            </TabbedShowLayout.Tab >
            <TabbedShowLayout.Tab label="resources.exams.tabs.results">
                <ReferenceManyField reference='results' target='exam_id' filter={{
                }}>
                    <Datagrid bulkActionButtons={false}>
                        <ReferenceField source='student_id' reference='students' link={false} >
                            <TextField source='id' label='resources.students.fields.id' />
                        </ReferenceField>
                        <ReferenceField source='student_id' reference='students' link={false} />
                        <NumberField source='marks' label='resources.results.fields.total_marks' />
                        <NumberField source='average' />
                        <TextField source='grade' />
                        <NumberField source='merit' />
                    </Datagrid>
                </ReferenceManyField>
            </TabbedShowLayout.Tab>
            <DocumentTab target="exam_id" />
        </TabbedShowLayout>
    </Show >
);
////////////////////////////////////// List /////////////////////////////////////////
export const ExamsList = () => (
    <List>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <DateField source="start"  {...CONSTANTS.dateOptions} />
            <DateField source="end"  {...CONSTANTS.dateOptions} />
            <ReferenceField source="class_id" reference="classes" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="session_id" reference="sessions" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <FunctionField label='bn.labels.action' render={exam => showExamAction(exam, <ResultShowButton />)} />
        </Datagrid>
    </List>
);
////////////////////////////////////// Edit /////////////////////////////////////////
const ExamEditToolbar = () => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const refresh = useRefresh();
    return record && (
        <TopToolbar>
            <ListButton />
            <ShowButton />
            {showExamAction(record)}
        </TopToolbar>
    )
}
const ExamEdit = props => {
    const { result_published, id } = useRecordContext();
    const redirect = useRedirect();
    const notify = useNotify();
    React.useEffect(() => {
        if (result_published) {
            notify('bn.errors.result_already_published', { type: 'warning' });
            redirect('/exams/' + id + '/show');
            return
        }
    }, []);
    const [subjects, setSubjects] = useState([]);
    const dataProvider = useDataProvider()
    const [loading, setLoading] = useState(false)
    React.useEffect(() => {
        if (id) {
            setLoading(true);
            dataProvider.getList('subjects', {
                filter: { exam_id: id },
            }).then(({ data }) => {
                setSubjects(data)
                setLoading(false);
            });
        }
    }, []);
    if (loading) return null;
    return (
        <SimpleForm>
            <TextInput source="name" />
            <DateTimeInput source="start" {...CONSTANTS.timeStampOptions} />
            <DateTimeInput source="end" {...CONSTANTS.timeStampOptions} />
            <ArrayInput source="subjects" validate={required()} defaultValue={subjects} >
                <SimpleFormIterator inline>
                    <NumberInput source="id" style={{ display: 'none' }} />
                    <TextInput source="name" helperText={false} />
                    <DateTimeInput source="start_at" validate={required()} />
                    <DateTimeInput source="end_at" validate={required()} />
                    <ReferenceInput reference="employees" source="teacher_id" validate={required()} />
                    <NumberInput source='full_marks' defaultValue={100} validate={required()} />
                </SimpleFormIterator>
            </ArrayInput>
            <BooleanInput source="result_published" />
            <AddMoreImage source="document_id" multiple label="resources.documents.name" name="পরীক্ষাসংক্রান্ত নথিপত্র" />
        </SimpleForm>
    )
}
export const ExamsEdit = () => {
    return <Edit mutationMode='pessimistic' actions={<ExamEditToolbar />} redirect="show">
        <ExamEdit />
    </Edit>
}
