import ReactDOM from "react-dom/client";
import React from "react";
const VieweDocument = ({ src: initSrc, id, closeWindow }) => {
    // load the document
    const [loading, setLoading] = React.useState(true);
    const [isImage, setIsImage] = React.useState(false);
    const [isPdf, setIsPdf] = React.useState(false);
    const [src, setSrc] = React.useState(initSrc);
    React.useEffect(async () => {
        const res = await fetch(initSrc);
        const contentType = res.headers.get('content-type');
        if (contentType === 'application/pdf')
            setIsPdf(true);
        else if (contentType.startsWith('image'))
            setIsImage(true);
        else if (contentType == 'application/svg+xml')
            setIsImage(true);
        else
            throw new Error('Invalid content type');
        const blob = await res.blob();
        const url = URL.createObjectURL(blob);
        setSrc(url);
        setLoading(false);
    }, [initSrc]);
    if (loading)
        return <div>Loading...</div>
    return <>
        {isPdf && (<iframe
            src={"https://mozilla.github.io/pdf.js/web/viewer.html?file=" + encodeURIComponent(initSrc)}
            title="Document Viewer"
            style={{ width: '100%', height: '100%' }}
        />
        )}
        {isImage && <img src={src} alt={id} style={{ width: '100%', height: '100%' }} />}
        <button onClick={closeWindow}>Close</button>
    </>
}
const viewImage = (e) => {
    e.stopPropagation();
    const src = e.target.src;
    // create a new window
    const newWindow = window.open('', '_blank', 'width=600,height=400,left=200,top=200')
    newWindow.document.title = 'Document Viewer'
    const _root = newWindow.document.createElement('div');
    newWindow.document.body.appendChild(_root);
    newWindow.onblur = newWindow.close;
    // render the component to the new window
    const root = ReactDOM.createRoot(_root)
    root.render(
        <VieweDocument src={src} id={e} closeWindow={newWindow.close} />
    )
}
export default viewImage;