import { defaultTheme } from "react-admin";
import createMuiTheme from "@mui/material/styles/createTheme";
import merge from "lodash/merge";

const theme = createMuiTheme(
    merge({}, defaultTheme, {
        palette: {
            // Your theme goes here
            // Write the following code to have an orange app bar. We'll explain it later in this article.
            // secondary: {
            //     main: "#ff9800", // Not far from orange
            // },
        },
        typography: {
            // Use the system font instead of the default Roboto font.
            fontFamily: ['Kalpurush'].join(','),
        },
        fontFamily: ['Kalpurush'].join(','),
    })
);
export default theme;