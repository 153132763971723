
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { useDataProvider, useTranslate, useRecordContext, useInput, useNotify, useRefresh, Tab, ReferenceField, FunctionField, SimpleForm } from "react-admin";

export const ResultPublisher = () => {
    const {id : subjectID, class_id : classID, session_id : sessionID, result_submitted, result_published} = useRecordContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const translate = useTranslate();
    const {field : {value : results, onChange : onResultsChange}} = useInput({source : 'results'});
    const [publishing, setPublishing] = React.useState(false); // The Data is being published
    const [publishIntent , setPublishIntent] = React.useState(false); // Clicking on publish button
    const [resultGrid, setResultGrid] = React.useState([]);
    const [resultGridError, setResultGridError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        // Load the result grid
        if(!subjectID) return;
        setLoading(true);
        dataProvider.getList('subjectresults', {
            filter : {
                subject_id : subjectID,
            }
        }).then(({data}) => {
            console.log(data)
            setResultGrid(data);
        })
        .catch(err => {
            setResultGridError(err);
            notify('Error while loading result grid', 'error');
        })
        .finally(() => setLoading(false));
    }, [subjectID]);
    return (
        loading ? <div>Loading...</div> : (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{translate('resources.students.fields.roll')}</TableCell>
                            <TableCell>{translate('resources.students.fields.name_bn')}</TableCell>
                            <TableCell>{translate('resources.students.fields.score')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {resultGrid.map(({id, student_id, student_name, roll, marks}, index) => (
                            <TableRow key={id}>
                                <TableCell>{roll}</TableCell>
                                <TableCell>
                                    <ReferenceField
                                        source="student_id"
                                        reference="students"
                                        record={{ student_id}}
                                        link={false}
                                    >
                                        <FunctionField
                                            render={({name_bn}) => name_bn}
                                        />
                                    </ReferenceField>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="number"
                                        value={marks || ''}
                                        onChange={(e) => {
                                            if(e.target.value > 100 || e.target.value < 0) return;
                                            const newResultGrid = [...resultGrid];
                                            //const index = newResultGrid.findIndex(({idV}) => idV === id);
                                            //console.log("Index", index)
                                            newResultGrid[index].marks = e.target.value;
                                            //console.log(newResultGrid)
                                            setResultGrid(newResultGrid);
                                            onResultsChange({
                                                target : {
                                                    value : newResultGrid
                                                }
                                            })
                                        }}
                                        sx={{
                                            width : '100px'
                                        }}
                                        inputProps={{
                                            min : 0,
                                            max : 100,
                                            step: 1
                                        }}
                                        disabled={result_submitted}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        )
    )
}
