import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 122.88 100"
      style={{
        position:'relative',
        width: 25,
      }}
    >
      <path
        d="M25.11 93.69V54.91h17.45c7.43 1.31 14.79 5.31 22.19 10H78.3c6.14.37 9.35 6.59 3.39 10.67-4.75 3.49-11 3.29-17.46 2.72-4.43-.22-4.62 5.72 0 5.75 1.61.13 3.36-.25 4.88-.25 8 0 14.63-1.54 18.67-7.88l2-4.74 20.2-10c10.09-3.32 17.27 7.23 9.83 14.57a263.86 263.86 0 01-44.92 26.45c-11.13 6.77-22.26 6.54-33.36 0l-16.42-8.51zM50.61 0l65.12 19.66-9.79 34.18-4.58-.7 7-25 .1-.32a4.47 4.47 0 00-3.12-5.48l-2.2-.6v-.07l-10.36-2.74L49.13 5.15 50.61 0zM39.84 6.9L105 26.55l-9.83 34.18-65.12-19.65L39.84 6.9zm22.69 21.76a8.09 8.09 0 11.34 11.42 8.09 8.09 0 01-.34-11.42zM49.34 15.24L92.43 28.6A5.26 5.26 0 0096 35.09l-3.82 13.52a5.25 5.25 0 00-6.49 3.61l-43.1-13.36A5.26 5.26 0 0039 32.37l3.86-13.52a5.25 5.25 0 006.5-3.61zM0 51.22h19.86v46.37H0V51.22z"
        style={{
            fillRule: "evenodd",
        }}
      ></path>
    </svg>
  );
}

export default Icon;