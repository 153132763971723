import {
    List,
    Create, Edit, SimpleForm, TextInput,
    TextField, RichTextField, Datagrid, BooleanInput, SelectInput,
    useTranslate,
    useRecordContext,
    NullableBooleanInput,
    useInput,
    Show,
    TabbedShowLayout,
    BooleanField,
    ReferenceManyField,
    NumberField,
    TopToolbar,
    ShowButton,
    ListButton,
    CreateButton,
    EditButton
} from 'react-admin';
import useMediaQuery from '@mui/material/useMediaQuery';
// import { BooleanNumField as BooleanField } from '../boolean';
import { Forbidden, ROLE_ACCOUNTANT, ROLE_ADMIN, ROLE_SUPER_ADMIN, Typography } from '../providers/forbidden';
import React, { useState } from 'react';
import Select from '@mui/material/Select';
import DocumentTab from './documents/documentTab';
import PaymentTab from './payments/paymentTab';
const SectorListToolbar = props => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
)
export const SectorList = props => (
    <List actions={<SectorListToolbar />}>
        <Datagrid rowClick="show" bulkActionButtons={false} >
            <TextField source="id" />
            <TextField source="name" />
           <BooleanField source="deposit" />
            <BooleanField source="student_related" />
            <BooleanField source="employee_related" />
        </Datagrid>
    </List>
);

const SectorRelation = ({ record }) => {
    const {field : {value : studentRelated, onChange : setStudentRelated}} = useInput({source: 'student_related', defaultValue: record?.student_related});
    const {field : {value : employeeRelated, onChange : setEmployeeRelated}} = useInput({source: 'employee_related', defaultValue: record?.employee_related});
    const [related, setRelated] = React.useState(!studentRelated && !employeeRelated ? '' : studentRelated.toString() );
    const translate = useTranslate();
    console.log(studentRelated, employeeRelated, related)
    return (
        <>
            <NullableBooleanInput
                source="related"
                record={{ related }}
                onChange={e => {
                    setStudentRelated({
                        target: {
                            value: e.target.value != "" && e.target.value == 'true'
                        }
                    });
                    setEmployeeRelated({
                        target: {
                            value: e.target.value != "" && e.target.value == 'false'
                        }
                    });
                    setRelated(e.target.value);

                }}
                hidden
                trueLabel='resources.sectors.fields.student_related'
                falseLabel='resources.sectors.fields.employee_related'
            />
            <BooleanInput
                    source="student_related"
                    label="resources.sectors.fields.student_related"
                    checked={studentRelated}
                    disabled
                />
            <BooleanInput
                source="employee_related"
                label="resources.sectors.fields.employee_related"
                disabled
                checked={employeeRelated}
            />
        </>
    )
}
const EditSectorToolbar = props => (
    <TopToolbar>
        <ShowButton />
        <ListButton />
    </TopToolbar>
)
export const SectorEdit = props => {
    const record = useRecordContext();
    return 1 ? (
        <Edit mutationMode='pessimistic' actions={<EditSectorToolbar />}>
            <SimpleForm>
                <TextField source="id" />
                <TextInput source="name" />
                <TextInput source="description" />
                <BooleanInput source="deposit" />
                <SectorRelation record={record} />
            </SimpleForm>
        </Edit>
    ) : <Forbidden />
}
const CreateSectorToolbar = props => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
)
export const SectorCreate = props => {
    const translate = useTranslate();
    return 1 ? (
        <Create redirect='list' actions={<CreateSectorToolbar />}>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="description" />
                <BooleanInput source="deposit" />
                
                <SectorRelation record={{ student_related: false, employee_related: false }} />
            </SimpleForm>
        </Create>
    ) : <Forbidden />;
}
const SectorShowToolbar = props => (
    <TopToolbar>
        <ListButton />
        <EditButton />
    </TopToolbar>
)
export const SectorShow = props => {
    const translate = useTranslate();
    return 1 ? (
        <Show actions={<SectorShowToolbar />}>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label='resources.sectors.tabs.details'>
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="description" />
                    <BooleanField source="deposit" />
                    <BooleanField source="student_related" />
                    <BooleanField source="employee_related" />
                </TabbedShowLayout.Tab>
                <PaymentTab target="sector_id" />
                <TabbedShowLayout.Tab label='resources.transactions.name'>
                    <ReferenceManyField label='resources.transactions.name' reference="transactions" target="sector_id">
                        <Datagrid>
                            <TextField source="id" />
                            <NumberField source="amount" />
                            <TextField source="type" />
                            <TextField source="description" />
                            <TextField source="created_at" />
                        </Datagrid>
                    </ReferenceManyField>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    ) : <Forbidden />;
}