import { Layout, AppBar, Button, TitlePortal, Toolbar } from "react-admin";
import pink from '@mui/material/colors/pink';
import BLUE from '@mui/material/colors/blue';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import BackIcon from '@mui/icons-material/ArrowBack';
import AndroidIcon from '@mui/icons-material/Android';
import Typography from '@mui/material/Typography';
import CONSTANTS from "./components/Constants";
const MyAppBar = props => {
    const navigate = useNavigate();
    var choice = localStorage.getItem(CONSTANTS.INSTALLED_KEY);
    try {
        if (choice)
            choice = JSON.parse(choice);
    } catch (e) {
        choice = null;
        localStorage.removeItem(CONSTANTS.INSTALLED_KEY);
    }
    return <AppBar {...props}>
        <Button variant="contained" color='error' onClick={() => navigate(-1)} label='ra.action.back'><BackIcon /></Button>
        <Box flex={1} />
        {choice?.outcome != 'accepted' && <Button
            variant="contained"
            color='success' onClick={() => {
                localStorage.removeItem(CONSTANTS.INSTALLED_KEY);
                window.location.reload();
            }} label='bn.labels.install'><AndroidIcon /></Button>
        }
    </AppBar>;
}
const CustomLayout = props => <Layout {...props} appBar={MyAppBar} sx={{
    '& .RaMenuItemLink-active' : {
        backgroundColor: BLUE[200],
    }
}} />;
export default CustomLayout;