import { ChipField, Datagrid, DateField, ImageField, NumberField, ReferenceField, ReferenceManyField, ShowButton, TabbedShowLayout, TextField, useRecordContext } from "react-admin";
import DownloadIcon from '@mui/icons-material/CloudDownload';
import Button from '@mui/material/Button';
import CONSTANTS from "../Constants";
const locales = {
    locales: "bn-BD"
}
const currencyOptions = {
    ...locales,
    options: {
        style: "currency",
        currency: "BDT",
        minimumFractionDigits: 0
    }
}
const DownloadButton = () => {
    const { id, url } = useRecordContext();
    return <Button
        component="a"
        variant='contained'
        href={url}
        target="_blank"
        download
        sx={{ marginRight: 1 }}
    ><DownloadIcon variant="contained" sx={{ marginRight: 1 }} /> Download</Button>
}
const paymentTab = ({ target, ...props }) => (
    <TabbedShowLayout.Tab label='resources.payments.name' {...props}>
        <ReferenceManyField label='resources.payments.name' reference="payments" target={target}>
            <Datagrid>
                <NumberField source="id" {...locales} />
                <NumberField source="amount" {...CONSTANTS.currencyOptions} />
                <ChipField source="status" />
                <DateField source="created_at" {...CONSTANTS.timeStampOptions} />
                
                <ShowButton />
            </Datagrid>
        </ReferenceManyField>
    </TabbedShowLayout.Tab>
)
export default paymentTab;
