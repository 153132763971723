import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import RetryIcon from '@mui/icons-material/CloudSync';
import UploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from '@mui/material/CircularProgress';
import fetchJSON from "../providers/fetcher";
import { useDataProvider, useInput, useRecordContext, useReference, useTranslate } from "react-admin";

if (!window.transferQueue) {
    window.transferQueue = (function () {
        return Promise.resolve();
    })();
}
var transferQueue = window.transferQueue;
const AddMoreFileButton = ({ onClick, size }) => (
    <UploadIcon onClick={onClick} style={{ width: size, height: size, cursor: 'pointer' }} />
)
const ImagePreview = ({ rawFile, id, isUploading, isUploaded, error, onRetry, onUploaded, onRemoved }) => {
    // Include all image types, because some browsers don't set the type correctly
    const isImage = rawFile.type.startsWith("image/") || rawFile.type === "application/svg+xml";
    const isPdf = rawFile.type === "application/pdf";
    return (
        <div style={{ position: 'relative', textAlign: 'center', margin: 4, display: 'inline-block', height: 200, maxHeight: 200, background: error ? '#f5b0b3' : 'white' }}>

            {isImage && <img
                src={rawFile instanceof File ? URL.createObjectURL(rawFile) : rawFile.src}
                alt={rawFile && rawFile.name}
                style={{
                    width: '95%',
                    height: '95%',
                    objectFit: 'contain',
                    border: 0,
                    margin: 0,
                    padding: 0,
                    opacity: error || isUploading ? 0.4 : 1
                }}
            />}
            {isPdf && <iframe
                src={rawFile instanceof File ? URL.createObjectURL(rawFile) : rawFile.src}
                alt={rawFile && rawFile.name}
                style={{
                    width: '95%',
                    height: '95%',
                    objectFit: 'contain',
                    border: 0,
                    margin: 0,
                    padding: 0,
                    opacity: error ? 0.1 : 1
                }}
            />}

            {isUploading ? (
                <CircularProgress style={{
                    position: 'absolute',
                    bottom: '5px',
                    right: '5px',
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }} color="primary" />
            ) : (<div
                style={{
                    // position: 'absolute',
                    // bottom: '5px',
                    // right: '5px',
                    // width: '20px',
                    // height: '20px',
                    // backgroundColor: 'red',
                    // borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}

            >
                {/* Retry Icon */}
                {/* You can replace this with your preferred retry icon */}
                {error && <RetryIcon color="warning" onClick={onRetry} />}
                <DeleteIcon color="error" onClick={e => onRemoved && onRemoved(id)} sx={{ marginLeft: 'auto', marginRight: 0, right: 0, alignSelf: 'right' }} />
            </div>
            )}
        </div>
    )
}
const AddMoreImage = ({ required = false, accessLevel = 'private', label = 'resources.documents.name', name, source, onUploaded, onRemoved, multiple, documentKey = "id", ...props }) => {
    const { field: { onChange, value: DocFile }, fieldState: { isTouched, error }, formState: { defaultValues } } = useInput({ source });
    const initialValue = defaultValues[source];
    const [initialDocument, setInitialDocument] = React.useState(null); // it would be used to reset the value
    const inputRef = React.useRef();
    const dataProvider = useDataProvider();
    const [images, setImages] = React.useState({}); // {id, rawFile, key, isUploading, isUploaded, error}
    const translate = useTranslate();
    React.useEffect(() => {
        // load initial value
        if (!initialValue) return;
        if (initialDocument) return;
        if (typeof initialValue == 'string') {
            setInitialDocument(initialValue);
            return;
        }
        dataProvider.getOne('documents', { id: initialValue })
            .then(({ data }) => {
                setInitialDocument(data.url);
                setImages({
                    [initialValue]: {
                        id: initialValue,
                        rawFile: {
                            src: data.url,
                            name: data.name,
                            type: data.mime
                        },
                        key: initialValue,
                        isUploading: false,
                        isUploaded: true,
                        error: null
                    }
                });
                if (!DocFile || DocFile == initialDocument) {

                }
            });

    }, [initialValue]);
    const upload = (id, reUpload = false) => {
        const image = images[id];
        if (!image) return;
        if (reUpload && !image.isUploading) {
            image.isUploaded = false;
            image.error = null;
            image.isUploading = true;
            setImages({ ...images });
        }
        const file = image.rawFile;
        transferQueue = transferQueue.then(() => {
            const formdata = new FormData();
            formdata.append('file', file);
            formdata.append('key', image.key);
            formdata.append('name', name || source);
            formdata.append('role', accessLevel);
            formdata.append('Content-Type', file.type);

            return fetchJSON('/api/documents/', {
                method: 'POST',
                body: formdata,
                encoding: 'unchanged'
            })
                .then(({ data }) => {
                    if (!data) throw new Error("bn.errors.no_data");
                    if (data.error) throw new Error(data.error);
                    //console.log("We received data", data);
                    images[id].isUploaded = true;
                    images[id].error = null;
                    images[id].document_id = data[documentKey];
                    if (multiple) {
                        onChange({
                            target: {
                                value: [...DocFile, data[documentKey]],
                                name: source
                            }
                        });
                    } else {
                        onChange({
                            target: {
                                value: data[documentKey],
                                name: source
                            }
                        });
                    }

                })
                .catch(err => {
                    images[id].error = err.message;
                    images[id].isUploaded = false;
                })
                .finally(() => {
                    images[id].isUploading = false;
                    setImages({ ...images });
                    if (images[id].isUploaded && onUploaded)
                        onUploaded(images[id]);
                    return Promise.resolve();
                })
        })
    }
    const addToStore = e => {
        console.log("Calling Add to Store")
        const files = e.target.files;
        if (!files.length) return;
        for (let i = multiple ? 0 : files.length - 1; i < files.length; i++) {
            const file = files[i];
            const id = Math.random().toString(36).substring(2, 15)
            const key = `${source}/${id}/${file.name}`;
            images[id] = {
                id,
                rawFile: file,
                key,
                isUploading: true,
                isUploaded: false,
                error: null,
                document_id: null
            };
            setImages({ ...images });
            upload(id);

        }
        e.target.value = null;
    }
    const removeFromStore = id => {
        const image = images[id];
        delete images[id];
        // destroy the image url
        URL.revokeObjectURL(image.rawFile);
        if (image.isUploaded) {
            transferQueue = transferQueue.then(() => {
                console.log("Deleting Image", image.key);
                return fetchJSON('/api/documents/' + image.document_id, {
                    method: 'DELETE'
                })
                    .then((data) => {
                        console.log("We received data", data);
                    })
                    .catch(err => {
                        console.log("Error Deleting Image", err);
                    })
                    .finally(() => {

                        if (onRemoved)
                            onRemoved(image);
                        return Promise.resolve();
                    })
            })
        }
        if (multiple) {
            [...DocFile].splice(DocFile.indexOf(image.document_id), 1);
            onChange({
                target: {
                    value: [...DocFile],
                    name: source
                }
            }
            );
        } else {
            onChange({
                target: {
                    value: null,
                    name: source
                }
            }
            );
        }


        setImages({ ...images });
    }
    return (
        <fieldset style={{
            padding: '5px'
        }}>
            <legend>{translate(label || source)}</legend>
            <input type="file" ref={inputRef} style={{ display: 'none' }} multiple={multiple} onInput={addToStore} />
            {Object.values(images).map(image => <ImagePreview {...image} required={required} key={image.id || image.key} onRemoved={removeFromStore} onRetry={() => upload(image.id, true)} />)}
            {(Object.keys(images).length < 1 || multiple) && <AddMoreFileButton size={230} onClick={e => { inputRef?.current?.click() }} />}
        </fieldset>
    )
}
export default AddMoreImage;