import React, { Fragment } from 'react';
import { createRoot } from 'react-dom/client';
import {
    List, Edit, Create, Datagrid, TextField, EditButton,
    ImageField,
    SimpleForm, TextInput, SelectInput, ReferenceInput,
    ReferenceField, Filter, Responsive, SimpleList,
    DateField, DateInput, BooleanInput,
    Show, SimpleShowLayout, ShowButton,
    DeleteButton, ArrayInput, SimpleFormIterator,
    Delete, required, ArrayField,
    regex,
    minLength,
    maxLength,
    SearchInput,
    ImageInput,
    RichTextField,
    NumberField,
    TopToolbar,
    ListButton, CheckboxGroupInput, translate,
    useTranslate, FormField, Button, ExportButton,
    CreateButton, BulkActionsToolbar, BulkUpdateButton, NumberInput,
    minValue, number, LinearProgress, NullableBooleanInput, useList, useRecordContext, TabbedShowLayout, TabbedShowLayoutTabs, TabbedForm, ReferenceManyField, FileField, useDataProvider, useInput, usePermissions, LoadingPage, FilterContext, useGetOne, LoadingIndicator, WithRecord, ListContext
} from 'react-admin';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Forbidden, SelectField, FeeDialog, ROLE_ACCOUNTANT, ROLE_ADMIN, ROLE_SUPER_ADMIN, Box } from '../providers/forbidden';
// import { BooleanNumField as BooleanField, BooleanNumField } from '../boolean';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import MyCustomImageInput from './imageInput';
import viewImage from './imageViewer';
import DownloadIcon from '@mui/icons-material/CloudDownload'
import PrintIcon from '@mui/icons-material/Print';
import CONSTANTS from './Constants';
import MuiTextInput from '@mui/material/TextField';
import MuiSelectionInput from '@mui/material/Select';
import MuiOption from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/CheckCircle';
import RightArrowIcon from '@mui/icons-material/East';
const BulkPaymentCreateButton = React.lazy(() => import('./payments/bulkfee'));
const PaymentTab = React.lazy(() => import('./payments/paymentTab'));
const DocumentTab = React.lazy(() => import('./documents/documentTab'));
const phoneFilter = [
    number(),
    minLength(11),
    maxLength(11),
    regex(/01[0-9]{9}/, "ra.validation.phone_number")
];
/********************************************CREATE */
const CreateToolBar = ({ basePath, ...props }) => (
    <TopToolbar {...props}>
        <ListButton />
    </TopToolbar>
);
export const StudentCreate = () => {
    const [needGuardian, setNeedGuardian] = React.useState(false);
    const [nonresident, setNonresident] = React.useState(true);
    const [sessionChoice, setSessionChoice] = React.useState([]);
    const [sessionDefaultValue, setSessionDefaultValue] = React.useState(null);
    const [bulkFeeDialog, setBulkFeeDialog] = React.useState(true);
    const { permissions, isLoading: permissionLoading } = usePermissions();
    const [class_id, setClassId] = React.useState(null);
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const styles = {
        imageInputSize: isSmall ? 100 : 200,
        imageInputMargin: isSmall ? 0 : 10,
        personalDetailsCardWidth: isSmall ? '100%' : '50%',
        imageCardWidth: isSmall ? '100%' : '50%',
        stackDirection: isSmall ? 'column' : 'row'
    }
    // Fetch available sessions
    const dataProvider = useDataProvider();
    React.useEffect(() => {
        if (!class_id) return setSessionChoice([]);
        dataProvider.getList('sessions', {
            filter: {
                class_id,
                //active: true
            },
        }).then(({ data }) => {
            data = data.map(item => ({
                id: item.id,
                name: item.start + ' - ' + (item.end ? item.end : 'বর্তমান')
            }));
            setSessionChoice(data);
        });
    }, [class_id]);

    return (
        !permissionLoading ? <Create actions={<CreateToolBar />}>
            <SimpleForm>
                <Stack direction={styles.stackDirection}>
                    <Card sx={{
                        width: styles.personalDetailsCardWidth,
                        marginRight: '10px',
                    }}>
                        <TextInput source="name_bn" validate={required()} /><br />
                        <TextInput source="name_en" validate={required()} /> <br />
                        <TextInput source="name_ar" validate={required()} inputProps={{
                            dir: 'rtl',
                            lang: 'ar'
                        }} /><br />
                        <TextInput source="father" validate={required()} /><br />
                        <TextInput source="mother" validate={required()} /><br />
                        <DateInput source="date_of_birth" validate={required()} fullWidth /><br />
                        <TextInput source="permanent_address" multiline validate={required()} /> <br />

                        <BooleanInput onChange={e => setNeedGuardian(e.target.checked)} source="needGuardian" />
                        {needGuardian && <TextInput source="guardian" required />}
                        {nonresident && <TextInput source="present_address" validate={required()} multiline />} <br />
                        {needGuardian && nonresident == false && <TextInput source="guardian_address" validate={required()} multiline />}
                        <TextInput source="parent_phone_no" validate={phoneFilter} required={!needGuardian} /><br />
                        {/* {needGuardian && (<TextInput source="guardian_phone" validate={[required(), ...phoneFilter]} />)}<br /> */}
                        

                        <SelectInput source="financial" choices={CONSTANTS.StudentFinancialStatus} validate={required()} fullWidth /><br />
                        <SelectInput source="accomodation" choices={CONSTANTS.StudentResidentialStatus} validate={required()} fullWidth /><br />
                        <ReferenceInput source="class_id" reference="classes">
                            <SelectInput value={class_id} optionText="name" required onChange={e => setClassId(e.target.value)} fullWidth />
                        </ReferenceInput>
                        <br />
                        {!!sessionChoice.length && <SelectInput
                            source="session_id"
                            choices={sessionChoice}
                            required
                            defaultValue={sessionDefaultValue}
                            fullWidth
                        />}
                    </Card>
                    <Card sx={{ width: styles.imageCardWidth, marginLeft: styles.imageInputMargin, left: 'auto', right: 0 }}>
                        <SelectInput source="blood_group" choices={CONSTANTS.BloodGroups} validate={required()} fullWidth /><br />
                        <TextInput source='note' multiline defaultValue={'মোবাইল ২:\nমোবাইল ৩:'} validate={maxLength(255, "সবমিলিয়ে সর্বোচ্চ ২৫৫ টি অক্ষর হতে হবে")} /><br />

                        <MyCustomImageInput size={styles.imageInputSize} name="ছাত্রের ছবি" source="photograph_id" label="resources.students.fields.photograph_id" />
                        <MyCustomImageInput size={styles.imageInputSize} name="ছাত্রের পরিচয়পত্র" source="id_ref" label="resources.students.fields.id_ref" />
                        <MyCustomImageInput size={styles.imageInputSize} name="ছাত্রের পিতামাতার পরিচয়পত্র" source="parent_id_ref" label="resources.students.fields.parent_id_ref" />
                        {needGuardian && (<MyCustomImageInput size={styles.imageInputSize} name="অভিভাবকের নথিপত্র" source="guardian_photo_id" label='resources.students.fields.guardian_photo_id' />)}<br />
                        <MyCustomImageInput size={styles.imageInputSize} name="ছাত্রের নথিপত্র" source="documents" multiple label="resources.students.fields.documents" />
                    </Card>
                </Stack>
            </SimpleForm>
        </Create> : <Forbidden />
    )
};


/*********************************************8 EDIT *********************************** */
const EditToolBar = ({ data, basePath, ...props }) => (
    <TopToolbar {...props}>
        <ShowButton />
        <ListButton />
    </TopToolbar>
);
const ClassSessionInput = () => {
    const { class_id: initialClass } = useRecordContext();
    const [sessionChoice, setSessionChoice] = React.useState([]);
    const [sessionDefaultValue, setSessionDefaultValue] = React.useState(null);
    const [class_id, setClassId] = React.useState(initialClass);
    const dataProvider = useDataProvider();
    const record = useRecordContext()
    React.useEffect(() => {
        if (!class_id) return setSessionChoice([]);
        dataProvider.getList('sessions', {
            filter: {
                class_id,
                //active: true
            },
        }).then(({ data }) => {
            data = data.map(item => ({
                id: item.id,
                name: item.start + ' - ' + (item.end ? item.end : 'বর্তমান')
            }));
            setSessionChoice(data);
        });
    }, [class_id]);

    return (
        <>
            <ReferenceInput validate={required()} source="class_id" reference="classes">
                <SelectInput value={class_id} optionText="name" onChange={e => setClassId(e.target.value)} />
            </ReferenceInput>
            {sessionChoice.length > 0 && <SelectInput
                source="session_id"
                choices={sessionChoice}
                validate={required()}
                defaultValue={sessionDefaultValue}
            />}
        </>
    )
}
export const StudentEdit = () => {
    const [needGuardian, setNeedGuardian] = React.useState(false);
    const [nonresident, setNonresident] = React.useState(true);
    const [bulkFeeDialog, setBulkFeeDialog] = React.useState(true);
    const { permissions, isLoading: permissionLoading } = usePermissions();
    // Fetch available sessions

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const styles = {
        imageInputSize: isSmall ? 100 : 200,
        imageInputMargin: isSmall ? 0 : 10,
        personalDetailsCardWidth: isSmall ? '100%' : '50%',
        imageCardWidth: isSmall ? '100%' : '50%',
        stackDirection: isSmall ? 'column' : 'row'
    }
    return (
        !permissionLoading ? <Edit mutationMode='pessimistic' actions={<EditToolBar />}>
            <TabbedForm>
                <TabbedForm.Tab label="resources.students.tabs.academic">
                    <ClassSessionInput />
                    <SelectInput source="accomodation" choices={CONSTANTS.StudentResidentialStatus} validate={required()} />
                    <SelectInput source="financial" choices={CONSTANTS.StudentFinancialStatus} validate={required()} />
                    <TextInput source="tution_fee" />
                </TabbedForm.Tab>
                <TabbedForm.Tab label="resources.students.tabs.personal">
                    <Stack direction={styles.stackDirection}>
                        <Card sx={{
                            width: styles.personalDetailsCardWidth,
                            marginRight: '10px'
                        }}>
                            <TextInput source="name_bn" validate={required()} fullWidth /><br />
                            <TextInput source="name_en" validate={required()} fullWidth /> <br />
                            <TextInput source="name_ar" validate={required()} fullWidth /><br />
                            <TextInput source="father" validate={required()} fullWidth /><br />
                            <TextInput source="mother" validate={required()} fullWidth /><br />
                            <DateInput source="date_of_birth" validate={required()} fullWidth /><br />

                            <BooleanInput onChange={e => setNeedGuardian(e.target.checked)} source="needGuardian" />
                            {needGuardian && <TextInput source="guardian" required />}
                            <TextInput source="permanent_address" multiline validate={required()} fullWidth /> <br />
                            {nonresident && <TextInput source="present_address" validate={required()} multiline />}
                            <br />
                            {needGuardian && nonresident == false && <TextInput source="guardian_address" validate={required()} multiline />}
                            <TextInput source="parent_phone_no" validate={phoneFilter} required={!needGuardian} fullWidth /><br />
                            {/* {needGuardian && (<TextInput source="guardian_phone" validate={[required(), ...phoneFilter]} />)}<br /> */}
                            <TextInput source='note' multiline defaultValue={'মোবাইল ২:\nমোবাইল ৩:'} validate={maxLength(255, "সবমিলিয়ে সর্বোচ্চ ২৫৫ টি অক্ষর হতে হবে")} />
                            <SelectInput source="blood_group" choices={CONSTANTS.BloodGroups} validate={required()} fullWidth /><br />
                        </Card>
                        <Card sx={{ width: styles.imageCardWidth, marginLeft: styles.imageInputMargin, left: 'auto', right: 0 }}>
                            <MyCustomImageInput size={styles.imageInputSize} name="ছাত্রের ছবি" source="photograph_id" label="resources.students.fields.photograph_id" />
                            <MyCustomImageInput size={styles.imageInputSize} name="ছাত্রের পরিচয়পত্র" source="id_ref" label="resources.students.fields.id_ref" />
                            <MyCustomImageInput size={styles.imageInputSize} name="ছাত্রের পিতামাতার পরিচয়পত্র" source="parent_id_ref" label="resources.students.fields.parent_id_ref" />
                            {needGuardian && (<MyCustomImageInput size={styles.imageInputSize} name="অভিভাবকের নথিপত্র" source="guardian_photo_id" label='resources.students.fields.guardian_photo_id' />)}<br />
                            <MyCustomImageInput size={styles.imageInputSize} name="ছাত্রের নথিপত্র" source="documents" multiple label="resources.students.fields.documents" />
                        </Card>
                    </Stack>
                </TabbedForm.Tab>

            </TabbedForm>
        </Edit> : <Forbidden />
    )
}
/****************************************************** SHOW**************************** */

const Student = ({ containerRef, student, translate, classDetails, sessionDetails, institutionDetails, documents, selfie, registeredByDetails, parent_id_ref, idRef, onLoad }) => {
    // Function to fetch translations based on the label name
    return (
        <div className="container" ref={containerRef}>
            <h2 style={{ textAlign: 'center', margin: "1px" }}>{institutionDetails?.name}</h2>
            <h3 style={{ textAlign: 'center', margin: "1px" }}>{institutionDetails?.address}</h3>
            <h3 style={{ textAlign: 'center', margin: "1px" }}>{translate('resources.students.fields.admission_form')}</h3>
            <h4 style={{ display: 'flex', justifyContent: 'space-between' }}>
                <b style={{ flex: 1, flexDirection: 'column', textAlign: 'left' }}>{translate('resources.students.fields.inst_id')} : #{student.inst_id}</b>
                <b style={{ flex: 1, flexDirection: 'column', textAlign: 'right' }}>{translate('resources.students.fields.registered_by')} : {registeredByDetails?.name} (#{registeredByDetails?.id})</b>
            </h4>
            <div className="form-columns">
                {/* Personal Details */}
                <fieldset className="form-column">
                    <legend>{translate('resources.students.tabs.personal')}</legend>
                    <div className="form-row">
                        <label htmlFor="name_bn">{translate('resources.students.fields.name_bn')}</label>
                        <input type="text" id="name_bn" name="name_bn" value={student.name_bn} />
                    </div>

                    <div className="form-row">
                        <label htmlFor="name_en">{translate('resources.students.fields.name_en')}</label>
                        <input type="text" id="name_en" name="name_en" value={student.name_en} />
                    </div>
                    <div className="form-row">
                        <label htmlFor="name_ar">{translate('resources.students.fields.name_ar')}</label>
                        <input type="text" id="name_ar" name="name_ar" value={student.name_ar} lang='ar' />
                    </div>

                    <div className="form-row" style={{ textAlign: 'left' }}>
                        <label htmlFor="date_of_birth">{translate('resources.students.fields.date_of_birth')}</label>
                        <input type="text" value={student.date_of_birth && new Date(student.date_of_birth).toLocaleDateString(CONSTANTS.locales.locales, CONSTANTS.dateOptions.options)} />
                    </div>


                    <div className="form-row">
                        <label htmlFor="father">{translate('resources.students.fields.father')}</label>
                        <input type="text" value={student.father} />
                    </div>

                    <div className="form-row">
                        <label htmlFor="mother">{translate('resources.students.fields.mother')}</label>
                        <input type="text" defaultValue={student.mother} />
                    </div>


                    <div className="form-row">
                        <label htmlFor="parent_phone_no">{translate('resources.students.fields.parent_phone_no')}</label>
                        <input type="text" id="parent_phone_no" defaultValue={student.parent_phone_no} />
                    </div>
                    <div className="form-row">
                        <label htmlFor="present_address">{translate('resources.students.fields.present_address')}</label>
                        <textarea id="present_address" name="present_address" rows={2}>{student.present_address}</textarea>
                    </div>
                    <div className="form-row">
                        <label htmlFor="permanent_address">{translate('resources.students.fields.permanent_address')}</label>
                        <textarea id="permanent_address" name="permanent_address" rows={2}>{student.permanent_address}</textarea>
                    </div>
                    {/* Academic Details */}
                    <div className="form-row">
                        <label htmlFor="class_id">{translate('resources.students.fields.class_id')}</label>
                        <input type="text" defaultValue={classDetails?.name + " #" + classDetails?.id} />
                    </div>

                    <div className="form-row">
                        <label htmlFor="session_id">{translate('resources.students.fields.session_id')}</label>
                        <input type="text" defaultValue={sessionDetails?.name + " #" + sessionDetails?.id} />
                    </div>

                    <div className="form-row">
                        <label htmlFor="admission_date">{translate('resources.students.fields.admission_date')}</label>
                        <input type="text" defaultValue={student.admission_date && new Date(student.admission_date).toLocaleDateString(CONSTANTS.locales.locales, CONSTANTS.dateOptions.options)} />
                    </div>
                    <div className="form-row">
                        <label htmlFor="financial">{translate('resources.students.fields.financial')}</label>
                        <select id="financial" name="financial" required defaultValue="1">
                            <option value="1" selected>{translate(student.financial)}</option>
                        </select>
                    </div>
                    <div className="form-row">
                        <label htmlFor="accomodation">{translate('resources.students.fields.accomodation')}</label>
                        <select id="accomodation" name="accomodation" required defaultValue="1">
                            <option value="1">{translate(student.accomodation)}</option>
                        </select>
                    </div>

                    <div className="form-row">
                        <label htmlFor="previous_institutions">{translate('resources.students.fields.previous_institutions')}</label>
                        <textarea id="previous_institutions" name="previous_institutions" rows={2}>{student.previous_institutions}</textarea>
                    </div>


                </fieldset>
                <fieldset>
                    <legend>{translate('resources.students.tabs.documents')}</legend>
                    <div className="form-row">
                        <figure>
                            <img
                                id="photograph_id"
                                src={selfie}
                            />
                            <figcaption>{translate('resources.students.fields.photograph_id')}</figcaption>
                        </figure>
                    </div>
                    <div className="form-row">
                        <figure>
                            <img
                                id="id_ref"
                                src={idRef}
                            />
                            <figcaption>{translate('resources.students.fields.id_ref')}</figcaption>
                        </figure>
                    </div>
                    <div className="form-row">
                        <figure>
                            <img
                                id="parent_id_ref"
                                src={parent_id_ref}
                            />
                            <figcaption>{translate('resources.students.fields.parent_id_ref')}</figcaption>
                        </figure>
                    </div>
                </fieldset>

            </div>
            <fieldset className='documents'>
                <legend>{translate('resources.students.tabs.documents')}</legend>
                {Object.keys(documents).map((key, index) => (
                    <img
                        key={index}
                        id={key}
                        src={documents[key]}
                    />
                ))}
            </fieldset>

        </div>
    );
};




const PrintButton = () => {
    const student = useRecordContext();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = React.useState(false);
    const translate = useTranslate();
    const exportButtonClick = async e => {
        e.preventDefault();
        setLoading(true)
        try {
            const { data: classDetails } = await dataProvider.getOne('classes', { id: student.class_id });
            const { data: sessionDetails } = await dataProvider.getOne('sessions', { id: student.session_id });
            const { data: institutionDetails } = await dataProvider.getOne('institutions', { id: student.inst_id });
            const { data: registeredByDetails } = await dataProvider.getOne('employees', { id: student.registered_by });
            const documents = await dataProvider.getManyReference('documents', {
                filter: {
                    student_id: student.id
                }
            })
                .then(({ data }) => {
                    const tempDict = {}
                    data.forEach((item) => {
                        tempDict[item.id] = item.url
                    }
                    )
                    return tempDict;
                })
            const selfie = documents[student.photograph_id]
            const idRef = documents[student.id_ref]
            const parent_id_ref = documents[student.parent_id_ref]
            delete documents[student.id_ref];
            delete documents[student.parent_id_ref];
            delete documents[student.photograph_id];
            student.financial = CONSTANTS.StudentFinancialStatus.find((item) => item.id === student.financial)?.name
            student.accomodation = CONSTANTS.StudentResidentialStatus.find((item) => item.id === student.accomodation)?.name
            const style = `
            /* Basic styling */
            
            .container {
              margin: 0 auto;
              display: flex;
                flex-direction: column;
                page-break-inside: avoid;
            }
            figcaption {
                text-align: center;
            }
            .documents {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
            }
            .documents img {
                margin: 10px;
                page-break-inside: avoid;
            }
            .form-columns {
                display: flex;
                justify-content: space-between;
            }
            .form-column {
                flex:1;
            }
            .form-row {
            
              display: flex;
              justify-content: space-between;
              margin-bottom:10px;
            }
        
            .form-row label {
              flex-basis: 45%;
            }
            img {
                max-width: 200px;
            }
            .form-row input[type="text"],
            .form-row textarea,
            .form-row select,
            .form-row input[type="date"] {
              flex-basis: 50%;
              padding: 8px;
              border: 1px solid #ccc;
              border-radius: 4px;
              box-sizing: border-box;
            }
        
            .form-actions {
              text-align: center;
              margin-top: 20px;
            }
        
            .form-actions input[type="submit"]:hover {
              background-color: #45a049;
            }
        
            /* Print styles */
            @media print {
              body {
                font-size: 12px;
              }
        
              .container {
                max-width: 100%;
                margin: 0;
                padding: 0;
              }
        
              .form-row label {
                width: 40%;
              }
        
              .form-row input[type="text"],
                .form-row textarea,
              .form-row select,
              .form-row input[type="date"] {
                width: 50%;
              }
        
              .form-actions {
                display: none;
              }
              .form-row {
                page-break-inside: avoid;
              }
              
            }`
            dataProvider.setPrintComponent(<Student
                student={student}
                translate={translate}
                classDetails={classDetails}
                sessionDetails={sessionDetails}
                institutionDetails={institutionDetails}
                documents={documents}
                selfie={selfie}
                idRef={idRef}
                parent_id_ref={parent_id_ref}
                registeredByDetails={registeredByDetails}
            />, style)
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }

    }
    return <Button
        label="ra.action.export"
        onClick={exportButtonClick}
        variant='contained'
        color='success'
        disabled={loading}
    ><PrintIcon /></Button>
}
const ShowLayoutToolbar = ({ basePath, data, resource }) => (
    <TopToolbar>
        <PrintButton />
        <EditButton />
        <ListButton />
    </TopToolbar>
);
const ResultTab = (props) => {
    const translate = useTranslate();
    const [dateFrom, setDateFrom] = React.useState(null)
    const [dateTo, setDateTo] = React.useState(null)
    return (
        <TabbedShowLayout.Tab label='resources.students.tabs.results' {...props}>
            <Box display='flex' justifyContent='left' sx={{ padding: 1, }}>
                <MuiTextInput type="date" label={translate('resources.results.fields.date_from')} value={dateFrom || ""} onChange={e => setDateFrom(e.target.value)} />
                <RightArrowIcon sx={{ mr: 1, alignSelf: 'center' }} />
                <MuiTextInput type="date" label={translate('resources.results.fields.date_to')} value={dateTo || ""} onChange={e => setDateTo(e.target.value)} />
            </Box>
            <ReferenceManyField reference='results' target='student_id' filter={{
                published: true,
                date_from: dateFrom,
                date_to: dateTo
            }}>
                <Datagrid bulkActionButtons={false}>

                    <ReferenceField source='session_id' reference='sessions' link={false}>
                        <TextField source='name' />
                    </ReferenceField>
                    <ReferenceField source='exam_id' reference='exams' link={false}>
                        <TextField source='name' />
                    </ReferenceField>
                    <ReferenceField source='exam_id' reference='exams' label='resources.exams.fields.end' link={false}>
                        <DateField source='end' label='resources.exams.fields.end' {...CONSTANTS.dateOptions} />
                    </ReferenceField>
                    <NumberField source='marks' />
                    <TextField source='grade' />
                    <NumberField source='merit' />
                </Datagrid>
            </ReferenceManyField>
        </TabbedShowLayout.Tab>
    )
}
export const StudentShow = () => {
    const { permissions, isLoading: permissionLoading } = usePermissions()
    const translate = useTranslate();
    return !permissionLoading && (
        permissions?.students?.viewAll || permissions?.students?.viewPersonal || permissions?.students?.viewOfficial ? <Show actions={<ShowLayoutToolbar />} className='printable'>
            <TabbedShowLayout>
                {(permissions?.students?.viewAll || permissions?.students?.viewPersonal) &&
                    <TabbedShowLayout.Tab label="resources.students.tabs.personal">
                        <Stack direction="row" spacing={5}>
                            <SimpleShowLayout>
                                <ReferenceField source="photograph_id" reference="documents" link={false}>
                                    <ImageField source="url" onClick={viewImage} />
                                </ReferenceField>
                                <NumberField source="id" {...CONSTANTS.locales} />
                                <TextField source="name_bn" lang='bn-BD' />
                                <TextField source="name_en" />
                                <TextField source="name_ar" dir='rtl' lang='ar' />

                                <DateField source="date_of_birth" {...CONSTANTS.dateOptions} />
                            </SimpleShowLayout>
                            <SimpleShowLayout>

                                <TextField source="father" />
                                <TextField source="mother" />
                                <RichTextField source="permanent_address" />
                                <RichTextField source="present_address" />
                                <RichTextField source="parent_phone_no" />

                                <TextField source="blood_group" />

                                <RichTextField source='note' />
                            </SimpleShowLayout>
                        </Stack>
                    </TabbedShowLayout.Tab>
                }
                {(permissions?.students?.viewAll || permissions?.students?.viewOfficial) &&
                    <TabbedShowLayout.Tab label="resources.students.tabs.academic">
                        <ReferenceField source="class_id" reference="classes" link={false}>
                            <TextField source="name" />
                        </ReferenceField>
                        <NumberField source="roll" {...CONSTANTS.locales} />
                        <NumberField source="tution_fee" {...CONSTANTS.currencyOptions} />
                        {permissions > ROLE_ACCOUNTANT && <NumberField source="due_amount" {...CONSTANTS.currencyOptions} />}
                        <SelectField source="accomodation" resource="students" choices={CONSTANTS.StudentResidentialStatus} />

                        <SelectField resource="students" source="active" choices={[
                            { id: true, name: 'resources.students.fields.active' },
                            { id: false, name: 'resources.students.fields.inactive' }
                        ]} />
                        <SelectField resource="students" source="financial" choices={CONSTANTS.StudentFinancialStatus} />
                    </TabbedShowLayout.Tab>
                }
                {(permissions?.students?.viewAll || permissions?.students?.viewOfficial) && <ResultTab />}
                {(permissions?.students?.viewAll || permissions?.students?.viewOfficial) && <PaymentTab target="student_id" />}
                {(permissions?.students?.viewAll || permissions?.students?.viewOfficial) && <DocumentTab target="student_id" />}
            </TabbedShowLayout>

        </Show> : <Forbidden />
    );
}
/****************************************************** LIST**************************** */
const SessionFilter = ({ class_id }) => {
    const [sessions, setSessions] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState(false)
    const dataProvider = useDataProvider();
    React.useEffect(() => {
        setLoading(true)
        dataProvider.getList('sessions', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'start', order: 'ASC' },
            filter: { class_id }
        }).then(({ data }) => {
            setSessions(data)
            setError(false)
        }).catch(() => setError(true)).finally(() => setLoading(false))
    }, [class_id]);
    if (loading) return <LinearProgress />
    if (error) return <span>Error...</span>
    return (
        <SelectInput key="session_id" source="session_id" alwaysOn choices={sessions?.map(item => ({
            id: item.id,
            name: item.start + ' - ' + (item.end ? item.end : 'বর্তমান')
        }))} />

    )
}
const StudentFilters = [
    <NullableBooleanInput key="payment_due" source="payment_due" alwaysOn defaultChecked={true} />,
    <ReferenceInput key="class_id" source="class_id" reference="classes" allowEmpty alwaysOn>
        <SelectInput key="class_id_s" optionText="name" />
    </ReferenceInput>,
    //<SessionFilter key="sessions" class_id={class_id} />,
    <SelectInput key="accomodation" source="accomodation" choices={CONSTANTS.StudentResidentialStatus} />,
    <SelectInput key="final" source="financial" choices={CONSTANTS.StudentFinancialStatus} />,
    <NullableBooleanInput
        source="active"
        defaultValue={true}
        trueLabel='resources.students.fields.active'
        falseLabel='resources.students.fields.inactive'
        key="active"
    />
]

const ListBulkToolbar = (props) => (
    <Fragment>
        <CreateButton />
        <BulkPaymentCreateButton related='students' />
        <ExportButton />
    </Fragment>
);
export const StudentList = () => {
    const { permissions, isLoading } = usePermissions();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const styles = {
        imageInputSize: isSmall ? 100 : 200,
        imageInputMargin: isSmall ? 0 : 10,
        personalDetailsCardWidth: isSmall ? '100%' : '50%',
        imageCardWidth: isSmall ? '100%' : '50%',
        stackDirection: isSmall ? 'column' : 'row'
    }
    if (isLoading) return <LoadingPage />
    return permissions?.students?.list ? (
        <List
            filters={StudentFilters}
        >
            <Datagrid rowClick="show" bulkActionButtons={<ListBulkToolbar />}>
                <NumberField source="id" {...CONSTANTS.locales} />
                <NumberField source="roll" {...CONSTANTS.locales} />
                <TextField source="name_bn" />
                <TextField source="parent_phone_no" />
                <NumberField source="due_amount"  {...CONSTANTS.currencyOptions} />
                {!isSmall && <EditButton />}
            </Datagrid>
        </List>
    ) : <Forbidden />
}

