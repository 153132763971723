/**
 * This would connect with the data provider and return the data
 */

//import { reportEvent, reportException, reportModal, reportTiming } from './analytics';
import { getManyReferenceFromCache, getOneFromCache, getManyFromCache, getListFromCache, createToCache } from './offlineDataprovider.js'
import fetchJSON from "./fetcher";
// Base URL is the URL from the document.location excluding the port number
/**
 * An utility to convert a nested object into a formdata so that files can be sent as well
 * @param {Object} obj The Object to be converted to formdata
 * @param {FormData} formData 
 * @returns FormData
 */
function json2query(obj) {
    const result = {};
    for (const key in obj) {
        if (obj[key] == undefined)
            continue;
        if (typeof obj[key] == 'object') {
            obj[key] = JSON.stringify(obj[key]);
        }
        result[key] = obj[key];
    }
    return result;
}

const dataProvider = {
    _fetcher : fetchJSON,
    getList: (resource, params, options) => {
        const flat = json2query(params);
        return fetchJSON(`/api/${resource}/?${new URLSearchParams(flat).toString()}`, options).then(data => ({ ...data, total: data.total ? (data.total + 1) : 0 }))
    },
    getOne: (resource, {id, meta }, options) => {
        const query = new URLSearchParams(json2query(meta));
        const url = `/api/${resource}/${id}?${query.toString()}`;
        return fetchJSON(url, options);
    },
    getBalance: (id) => {
        const url = `/api/institutions/${id}/credits`;
        return fetchJSON(url);
    },
    getMany: (resource, params, options) => {
        const flat = json2query(params);
        console.log(options)
        let qs = new URLSearchParams(flat);
        const url = `/api/${resource}/?${qs.toString()}`;
        return fetchJSON(url, options);
    },
    getManyReference: (resource, {target, id, ...params}, options) => {
        //
        console.log(params)
        
        const flat = {
            filter: JSON.stringify({
                [target]: id,
                ...(params?.filter || {})
            }),
            sort: JSON.stringify(params.sort),

        };
        let qs = new URLSearchParams(flat);
        const url = `/api/${resource}/?${qs.toString()}`;
        return fetchJSON(url, options);

    },
    create: (resource, params) => {
        const url = `/api/${resource}/`;
        const now = window.performance.now()
        return fetchJSON(url, {
            method: 'POST',
            body: JSON.stringify(params.data),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        }).then(res => /*reportTiming(resource + '/create', url, window.performance.now() - now) || reportEvent(resource)('create')() ||*/ res);
        
    },
    update: (resource, { data, previousData, ...rest }) => {

        const url = `/api/${resource}/${data.id}`;
        const now = window.performance.now()
        data = JSON.stringify(data);
        return fetchJSON(url, {
            method: 'POST',
            body: data,
            headers: new Headers({ 'Content-Type': 'application/json' }),
        }).then(res => /*reportTiming(resource + '/update', url, window.performance.now() - now) || reportEvent(resource)('update')() ||*/ res);
    },
    delete : (resource, { id, previousData, ...rest }) => {
        const url = `/api/${resource}/${id}`;
        const now = window.performance.now()
        return fetchJSON(url, {
            method: 'DELETE',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        }).then(res => /*reportTiming(resource + '/delete', url, window.performance.now() - now) || reportEvent(resource)('delete')() ||*/ res);
    },
    deleteMany: (resource, params) => {
        const url = `/api/${resource}/`;
        const now = window.performance.now()
        return fetchJSON(url, {
            method: 'DELETE',
            body: JSON.stringify(params.ids),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        }).then(res => /*reportTiming(resource + '/delete', url, window.performance.now() - now) || reportEvent(resource)('delete')() ||*/ res);
    },
    updateMany : (resource, {ids, data}) => {
        const url = `/api/${resource}/bulkUpdate`;
        const now = window.performance.now()
        return fetchJSON(url, {
            method: 'POST',
            body: {
                ids,
                ...data
            },
        }).then(res => /*reportTiming(resource + '/update', url, window.performance.now() - now) || reportEvent(resource)('update')() ||*/ res);
    },
    createMany : (resource, {data}) => {
        const url = `/api/${resource}/bulkCreate`;
        const now = window.performance.now()
        return fetchJSON(url, {
            method: 'POST',
            body: {
                ...data
            },
        }).then(res => /*reportTiming(resource + '/update', url, window.performance.now() - now) || reportEvent(resource)('update')() ||*/ res);
    },
}
export default dataProvider;