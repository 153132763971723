import { ListGuesser, ShowGuesser, EditGuesser, Show, SimpleShowLayout, RichTextField, ImageField, TopToolbar, ListButton, CreateButton, EditButton, SimpleForm, TextInput, ReferenceInput, Create } from "react-admin";

import { Datagrid, DateField, List, ReferenceField, TextField } from 'react-admin';
import AddMoreImage from "./imageInput";
import CONSTANTS from "./Constants";
////////////////////////////////////// Create /////////////////////////////////////////
const NoticeCreateToolbar = props => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
)
export const NoticesCreate = () => (
    <Create actions={<NoticeCreateToolbar />}>
        <SimpleForm>
            <TextInput source="title" />
            <TextInput multiline source="content" />
            <ReferenceInput source="related_notice" reference="notices">
                <TextField source="title" />
            </ReferenceInput>
            <AddMoreImage source="document_id" label="resources.documents.name" name='বিজ্ঞপ্তিসংক্রান্ত নথি' />
        </SimpleForm>
    </Create>
);

////////////////////////////////////// Show /////////////////////////////////////////
const NoticeShowToolbar = props => (
    <TopToolbar>
        <ListButton />
        <CreateButton />
    </TopToolbar>
)
export const NoticesShow = () => (
    <Show actions={<NoticeShowToolbar />}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="title" />
            <RichTextField source="content" />
            <DateField source="timestamp" {...CONSTANTS.timeStampOptions} />
            <ReferenceField source="document_id" reference="documents" link='show'>
                <ImageField source="url" />
            </ReferenceField>
           
        </SimpleShowLayout>
    </Show>
);
////////////////////////////////////// List /////////////////////////////////////////
export const NoticesList = () => (
    <List>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="title" />
            <DateField source="timestamp" {...CONSTANTS.timeStampOptions}/>
        </Datagrid>
    </List>
);