import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
Sentry.init({
  dsn: "https://576fb864e94f487cb8682688fd38ab78@o249367.ingest.sentry.io/4505573791432704",
  //debug: process.env.NODE_ENV === 'development',
  beforeSend(event, hint) {
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
  environment: process.env.NODE_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://idaratulmadrasa.com"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  release: process.env.REACT_APP_VERSION || "dev"
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Sentry.ErrorBoundary
    fallback={<p style={{ fontWeight: 'bolder', fontSize: '150%', color: 'red', textAlign: 'center' }}>মারাত্মক কোনো ঝামেলা হয়েছে। অনুগ্রহপূর্বক খুব শীঘ্রই ডেভেলপারের সঙ্গে যোগাযোগ করুন</p>}
    showDialog
    dialogOptions={
      {
        title: 'মারাত্মক কোনো ঝামেলা হয়েছে। অনুগ্রহপূর্বক খুব শীঘ্রই ডেভেলপারের সঙ্গে যোগাযোগ করুন',
        subtitle: 'সাময়িক সমস্যার জন্য আমরা আন্তরিকভাবে দুঃখিত',
        subtitle2: 'আপনার সহায়তা করার জন্য ধন্যবাদ',
        lang: 'bn'
      }
    }
  >
    <App />
  </Sentry.ErrorBoundary>
);
console.log("Version: ", process.env.REACT_APP_VERSION);
reportWebVitals();
