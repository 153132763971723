const MINIMUM_DATE = new Date('2023-06-20');
const IDARAH_LOGO = '/idarah192.png';
const INSTALLED_KEY = 'installed';
type Option = {
    id : number | string;
    name : string;
}
type Options = Option[];
const locales = {
    locales: "bn-BD"
};
const currencyOptions = {
    ...locales,
    options : {
        style: "currency",
        currency: "BDT",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }
}
const dateOptions = {
    ...locales,
    options: {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'Asia/Dhaka'
    }
}
const timeOptions = {
    ...locales,
    options: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone : 'Asia/Dhaka',
        dayPeriod: "short"
    }
}
const timeStampOptions = {
    ...locales,
    options: {
        ...dateOptions.options,
        ...timeOptions.options
    }
}
const paymentStatus : Options = [
    { id: 'due', name: 'resources.payments.status.due' },
    { id: 'paid', name: 'resources.payments.status.paid' },
    { id: 'waived', name: 'resources.payments.status.waived' }
]

const StudentResidentialStatus : Options = [
    { id: 'resident', name: 'resources.students.accomodations.resident' },
    { id: 'non_resident', name: 'resources.students.accomodations.non_resident' },
]
const EmployeeResidentialStatus : Options = [
   ...StudentResidentialStatus,
    { id: 'part_time', name: 'resources.employees.fields.part_time' } // Part Time
]
const EmployeeResidentialStatusMap : {
    [key: string]: Option
} = {
    resident: EmployeeResidentialStatus[0],
    non_resident: EmployeeResidentialStatus[1],
    part_time: EmployeeResidentialStatus[2]
}
const StudentFinancialStatus : Options = [
    { id: 'orphan', name: 'resources.students.fields.financials.orphan' },
    { id: 'poor', name: 'resources.students.fields.financials.poor' },
    { id: 'miskin', name: 'resources.students.fields.financials.miskin' },
    { id: 'able', name: 'resources.students.fields.financials.able' },
]
const BloodGroups : Options = [
    { id: 'A+', name: 'A+' },
    { id: 'A-', name: 'A-' },
    { id: 'B+', name: 'B+' },
    { id: 'B-', name: 'B-' },
    { id: 'AB+', name: 'AB+' },
    { id: 'AB-', name: 'AB-' },
    { id: 'O+', name: 'O+' },
    { id: 'O-', name: 'O-' },
]
/**
 * Qualification List
 */
const Qualifications : {
    [key: string]: Option
} = {
    doctor: { id: 'doctor', name: 'resources.employees.qualifications.hafez' },
    mawlana: { id: 'mawlana', name: 'resources.employees.qualifications.mawlana' },
    mufti: { id: 'mufti', name: 'resources.employees.qualifications.mufti' },
    qari: { id: 'qari', name: 'resources.employees.qualifications.qari' },
    master: { id: 'master', name: 'resources.employees.qualifications.master' },
    cook: { id: 'cook', name: 'resources.employees.qualifications.cook' },
    gatekeeper: { id: 'gatekeeper', name: 'resources.employees.qualifications.gatekeeper' },
    khadem: { id: 'khadem', name: 'resources.employees.qualifications.khadem' },
    engineer: { id: 'engineer', name: 'resources.employees.qualifications.engineer' },
    professor: { id: 'professor', name: 'resources.employees.qualifications.professor' },
}
const EmployeeQualifications : Options = Object.values(Qualifications);
type ROLE = "admin" | "accountant" | "teacher" | "employee" | "superAdmin";
const ROLE_MAP : {
    [key in ROLE]: Option
} = {
    admin: { id: 'admin', name: 'resources.employees.roles.admin' },
    accountant: { id: 'accountant', name: 'resources.employees.roles.accountant' },
    teacher: { id: 'teacher', name: 'resources.employees.roles.teacher' },
    employee: { id: 'employee', name: 'resources.employees.roles.employee' },
    superAdmin: { id: 'superAdmin', name: 'resources.employees.roles.superAdmin' },
};
const ROLES : Options = Object.values(ROLE_MAP);
const toLocalDate = (date : Date) => new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
////////////////// Dashboard ///////////////////////
const CURRENT_INSTITUTION_KEY = 'curInst';
const INSTITUTION_MAP_KEY = 'instMap';
const PERMISSION_KEY = '_p';
const USER_DETAILS_KEY = '_u';
const TOKEN_KEY = 'token';
const LOCALE_KEY = 'locale';
const CONSTANTS : {
    LOCALE_KEY: typeof LOCALE_KEY;
    toLocalDate: typeof toLocalDate;
    INSTALLED_KEY: typeof INSTALLED_KEY;
    locales: typeof locales;
    currencyOptions: typeof currencyOptions;
    dateOptions: typeof dateOptions;
    timeStampOptions: typeof timeStampOptions;
    timeOptions: typeof timeOptions;
    paymentStatus: typeof paymentStatus;
    EmployeeResidentialStatus: typeof EmployeeResidentialStatus;
    StudentResidentialStatus: typeof StudentResidentialStatus;
    EmployeeQualifications: typeof EmployeeQualifications;
    ROLES: typeof ROLES;
    CURRENT_INSTITUTION_KEY: typeof CURRENT_INSTITUTION_KEY;
    INSTITUTION_MAP_KEY: typeof INSTITUTION_MAP_KEY;
    MINIMUM_DATE: typeof MINIMUM_DATE;
    PERMISSION_KEY: typeof PERMISSION_KEY;
    USER_DETAILS_KEY: typeof USER_DETAILS_KEY;
    TOKEN_KEY: typeof TOKEN_KEY;
    StudentFinancialStatus: typeof StudentFinancialStatus;
    IDARAH_LOGO : typeof IDARAH_LOGO;
    Qualifications : typeof Qualifications;
    BloodGroups : typeof BloodGroups;
} & { [key: string]: any
}= {
    LOCALE_KEY,
    toLocalDate,
    locales,
    currencyOptions,
    dateOptions,
    paymentStatus,
    EmployeeResidentialStatus,
    StudentResidentialStatus,
    EmployeeQualifications,
    ROLES,
    CURRENT_INSTITUTION_KEY,
    INSTITUTION_MAP_KEY,
    MINIMUM_DATE,
    PERMISSION_KEY,
    USER_DETAILS_KEY,
    TOKEN_KEY,
    timeStampOptions,
    timeOptions,
    StudentFinancialStatus,
    IDARAH_LOGO,
    Qualifications,
    INSTALLED_KEY,
    BloodGroups,
    EmployeeResidentialStatusMap
};
export default CONSTANTS;