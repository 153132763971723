import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import CheckBox from '@mui/material/Checkbox'
import DialogTitle from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import PaidIcon from '@mui/icons-material/Paid';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PeopleIcon from '@mui/icons-material/People';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import Red from '@mui/material/colors/red';
import GREEN from '@mui/material/colors/green';
import React from 'react';
import { useRedirect, ImageField, ReferenceField, useRecordContext } from 'react-admin';
import { useTranslate, usePermissions } from 'react-admin';
export const ForbiddentContent = () => {
    const translate = useTranslate();
    const redirect = useRedirect();
    const {isLoading, permissions} = usePermissions(ROLE_ADMIN);
    return !isLoading && <Grid container spacing={5} style={{ margin: '10px' }}>
        <Paper elevation={3} style={{ padding: '10px' }}>
            <Typography variant='h5' style={{ textAlign: 'center', margin: '5px' }}>নিচের মেনু থেকে বাছাই করুন</Typography>

            {/* <Button startIcon={<ListIcon />}  onClick={e => redirect('list', '/sectors')}>
            {translate('resources.sectors.fields.list')}
        </Button> */}
            {/* <Button startIcon={<TransactionIcon />}  onClick={e => redirect('create', '/sectors')}>
            {translate('resources.sectors.fields.create')}
        </Button> */}
            {/* </Paper>
    <Paper elevation={3} style={{ margin: '20px' }}>
    <Typography variant='h5' style={{ textAlign:'center', margin:'5px'}}>{translate('resources.students.name')}</Typography> */}

            {/* <Button startIcon={<AddShoppingCartIcon />} onClick={e => redirect('create', '/orders')}>
                {translate('resources.orders.fields.create')}
            </Button>
            <Button startIcon={<InventoryOutlinedIcon />} onClick={e => redirect('list', '/orders')}>
                {translate('resources.orders.fields.list')}
            </Button>
            <br />
            <Button startIcon={<AddBusinessIcon />} onClick={e => redirect('create', '/products')}>
                {translate('resources.products.fields.create')}
            </Button>
            <Button startIcon={<InventoryIcon />} onClick={e => redirect('list', '/products')}>
                {translate('resources.products.fields.list')}
            </Button>
            <br />
            <Button startIcon={<PaidIcon />} onClick={e => redirect('create', '/transactions')}>
                {translate('resources.transactions.fields.create')}
            </Button>
            {permissions && <Button startIcon={<AccountBalanceIcon />} onClick={e => redirect('list', '/transactions')}>
                {translate('resources.transactions.fields.list')}
            </Button>}
            <br/>
            {permissions && <Button startIcon={<PeopleIcon />} onClick={e => redirect('list', '/employees')}>
                {translate('resources.employees.fields.list')}
            </Button>}
            {permissions && <Button startIcon={<AddModeratorIcon />} onClick={e => redirect('create', '/employees')}>
                {translate('resources.employees.fields.create')}
            </Button>} */}
        </Paper>
        {/* <Paper elevation={3} style={{ margin: '20px' }}>
    <Typography variant='h5' style={{ textAlign:'center', margin:'5px'}}>{translate('resources.staffs.name')}</Typography>
        
        <Button startIcon={<NewIcon />}  onClick={e => redirect('create', '/staffs')}>
            {translate('resources.staffs.fields.create')}
        </Button>
        <Button startIcon={<TransactionIcon />}  onClick={e => redirect('create', '/staffPayments')}>
            {translate('resources.staffPayments.fields.create')}
        </Button>
    </Paper> */}
    </Grid>
}
export const Forbidden = ({ permissions, ...props }) => (
    <Card>
        <CardContent>
            <ForbiddentContent />
        </CardContent>
    </Card>
);
const SelectField = ({  source, choices, label, resource, ...props }) => {
    const record = useRecordContext();
    const translate = useTranslate('bn');
    if(!record) return null;
    let value = record[source];
    label = label ? translate(label) : translate(`resources.${resource}.fields.${source}`);
    let choice = choices.find(choice => choice.id == value);
    return choice ? <Typography component="span" variant="body2" {...props}>
        <b style={{ fontSize: '100%' }}>{label}</b> : {translate(choice.name)}
    </Typography> : null;
}
const CheckBoxInput = ({ label, value, onChange }) => {

    return <FormControlLabel control={<CheckBox defaultChecked={value} />} label={label} onChange={onChange} />
}

const FeeDialog = ({ state, setState, ...props }) => {
    const translate = useTranslate('bn');
    console.log(props)
    return (
        <Dialog open={true}>
            <DialogTitle>
                {translate('resources.staffs.fields.permissions')}
            </DialogTitle>
            <DialogContent>
                Content
            </DialogContent>
        </Dialog>
    )
}
const Document = ({ record, ...props }) => (
    <ReferenceField source="id" {...props} reference="photos">
        <ImageField record={record} source="path" {...props} />
    </ReferenceField>
)
export const ROLE_DEVELOPER = 3
export const ROLE_ADMIN = 2;
export const ROLE_SUPER_ADMIN = 4;
export const ROLE_ACCOUNTANT= 1;
export const ROLE_EMPLOYEE = 0;
export {
    Box, Typography, SelectField,
    CheckBoxInput,
    GREEN,
    Red,
    FeeDialog,
    Document
};