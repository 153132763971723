import {
    List, Show, Edit,
    Create, Datagrid,
    TextField, EditButton,
    SimpleForm, TextInput,
    NumberField, NumberInput,
    DateField,
    RichTextField,
    ReferenceField,
    ImageField,
    ImageInput,
    SelectInput,
    useRedirect,
    SimpleShowLayout,
    WrapperField
} from 'react-admin';
import React from 'react';
import { Forbidden, ROLE_ACCOUNTANT, ROLE_ADMIN, ROLE_SUPER_ADMIN, Typography } from '../../providers/forbidden';
import AddMoreImage from '../imageInput';
import Stack from '@mui/material/Stack';
import CONSTANTS from '../Constants';
export const DocumentList = props => 1 ? (
    <List >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <ImageField source="url" />
            <ReferenceField source="uploaded_by" reference="employees" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <DateField source="created_at" {...CONSTANTS.timeStampOptions} />
        </Datagrid>
    </List>
) : <Forbidden />;
export const DocumentCreate = props => {
    const [name, setName] = React.useState('');
    const [role, setrole] = React.useState('');
    const roleChoices = [
        { id: 'public', name: 'resources.documents.roles.public' },
        { id: 'private', name: 'resources.documents.roles.private' },
    ]
    const [isEditable, setIsEditable] = React.useState(true);
    const isFilled = name != '' && !!role;
    const redirect = useRedirect();
    const key = role + name;
    return (<Create>
        <SimpleForm>
            <TextInput source="name" disabled={!isEditable} onChange={e => setName(e.target.value)} />
            <SelectInput source="role" choices={roleChoices} defaultValue='private' disabled={!isEditable} onChange={e => setrole(e.target.value)} required />
            {isFilled && <Typography variant="h5" component='b' sx={{
                color: 'red'
            }}>
                আগেভাগে সব তথ্য দেখে নিন, একবার সব তথ্য দেওয়ার পর পরিবর্তন করা যাবে না।
            </Typography>
            }
            {isFilled && <AddMoreImage source="id" onUploaded={e => redirect(`/documents/${e.document_id}/show`)} documentKey="id" key={key} />}

        </SimpleForm>
    </Create>
    );
}
export const DocumentEdit = props => (
    <Edit mutationMode='pessimistic' {...props}>
        <SimpleForm>
            <TextField source="id" />
            <ImageField source="url" title="title" />
            <TextInput source="name" />

        </SimpleForm>
    </Edit>
);
export const DocumentShow = props => (
    <Show >
        <Stack direction="row" spacing={2}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="name" />
                <RichTextField source="description" />
                <ReferenceField source="uploaded_by" reference="employees" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="created_at" {...CONSTANTS.timeStampOptions} />
            </SimpleShowLayout>
            <SimpleShowLayout>
                <ImageField source="url" />
            </SimpleShowLayout>
        </Stack>
    </Show>
);