import React from 'react';
import { BooleanField, BooleanInput, Create, Datagrid, DateField, DateTimeInput, Edit, EditButton, FunctionField, List, ListButton, ListGuesser, NumberField, ReferenceField, ReferenceManyField, Show, ShowButton, ShowGuesser, SimpleForm, SimpleShowLayout, TabbedShowLayout, TextField, TextInput, TopToolbar, useRecordContext } from 'react-admin';
import { Button, LoadingPage, useDataProvider, useInput, usePermissions, useTranslate, DateInput, required } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';
import CONSTANTS from '../Constants';
import { ResultPublisher } from './publishResult';

export const AddSubject = ({ record }) => {
    const { permissions, isLoading: permissionLoading } = usePermissions();
    const { field: { value: subjectList, onChange: setSubjectList } } = useInput({ source: 'subjects', defaultValue: [] });
    const [subjects, setSubjects] = React.useState({});
    const [isNewSubject, setIsNewSubject] = React.useState(false);
    const [newStartDate, setNewStartDate] = React.useState(new Date().toDateString());
    const [newEndDate, setNewEndDate] = React.useState('');
    const [newSubjectName, setNewSubjectName] = React.useState(record?.name || '');
    const newTeacher = useInput({source: 'teacher_id', defaultValue: record?.teacher_id});
    const [newFullMarks, setNewFullMarks] = React.useState(record?.full_marks || 100);
    const [isLoading, setIsLoading] = React.useState(permissionLoading);
    const dataProvider = useDataProvider();
    const startRef = null && React.forwardRef();
    const nameRef = null && React.forwardRef();
    const translate = useTranslate()
    React.useEffect(() => {
        if (record?.id) {
            setIsLoading(true);
            dataProvider.getList('subjects', {
                filter: { exam_id: record.id },
            }).then(({ data }) => {
                for (const subject of data) {
                    subjects[subject.id] = subject;
                }
                setSubjects({ ...subjects });
                setIsLoading(false);
            });
        }
    }, []);
    const addSubject = () => {
        console.log(nameRef)
        if (!newSubjectName) return nameRef?.current?.focus();
        if (!newStartDate) return startRef?.current?.focus();
        const subjectObj = {
            name: newSubjectName,
            start: newStartDate,
            end: newEndDate,
            subject_id: record?.id,
            id: null,
            full_marks : newFullMarks
        };
        setSubjectList({
            target: {
                value: [...subjectList, subjectObj]
            }
        });
        const lastSubjectID = Math.max(...Object.keys(subjects).map(key => parseInt(key)));
        setSubjects({
            ...subjects,
            [lastSubjectID + 1]: subjectObj
        })
        setIsNewSubject(false);
        setNewSubjectName('');
        setNewStartDate('');
        setNewEndDate('');
    }
    return isLoading ? <LoadingPage /> :  (
        <table border={1} style={{ width: '100%', borderBottom: '1px solid black', textAlign: 'center' }}>
            <thead>
                <tr>
                    <th>{translate("resources.subjects.fields.name")}</th>
                    <th>{translate('resources.subjects.fields.start')}</th>
                    <th>{translate('resources.subjects.fields.end')}</th>
                    <th>{translate('resources.subjects.fields.full_marks')}</th>
                    <th>{translate('resources.actions')}</th>

                </tr>
            </thead>
            <tbody>
                {Object.values(subjects)?.map((subject, index) => {
                    return <tr key={index} style={{
                        marginBottom: '10px',
                        padding: '5px'
                    }}>
                        <td >{subject.name}</td>
                        <td>{subject.start}</td>
                        <td>{subject.end}</td>
                    </tr>
                }
                )}
                {isNewSubject ? <tr>
                    <td><TextInput defaultValue={record?.name} ref={nameRef} helperText={!newSubjectName ? translate('ra.validation.required') : ''} color={!newSubjectName ? 'error' : 'success'} label="resources.subjects.fields.name" source={"subjectName" + subjectList.length} value={newSubjectName} onChange={e => setNewSubjectName(e.target.value)} /></td>
                    <td><DateTimeInput defaultValue={new Date().toDateString()} ref={startRef} helperText={!newStartDate ? translate('ra.validation.required') : ''} color={!newStartDate ? 'error' : 'success'} label='resources.subjects.fields.start' validate={required()} source={"start" + subjectList.length} value={newStartDate} onChange={e => setNewStartDate(e.target.value)} /></td>
                    <td><DateTimeInput helperText='' label='resources.subjects.fields.end' source={"end" + subjectList.length} value={newEndDate} onChange={e => setNewEndDate(e.target.value)} /></td>

                    <td><TextInput helperText='' label='resources.subjects.fields.full_marks' source={"fullMarks" + subjectList.length} value={newFullMarks} onChange={e => setNewFullMarks(e.target.value)} /></td>
                </tr> : null}
            </tbody>
            <tfoot>
                <tr>

                    {isNewSubject ? <td>
                        <Button
                            label='ra.action.save'
                            onClick={addSubject}
                            variant="contained"
                            color="success"
                            size='small'
                            sx={{
                                marginRight: '10px'
                            }}
                        >
                            <SaveIcon />
                        </Button><Button
                            label='ra.action.cancel'
                            onClick={() => setIsNewSubject(false)}
                            variant="contained"
                            color="error"
                            size='small'
                        >
                            <CancelIcon />
                        </Button>
                    </td> : <td colSpan={4}> <Button
                        label='resources.subjects.action.add'
                        onClick={() => setIsNewSubject(true)}
                        variant="contained"
                        color="primary"
                        size='small'
                    >
                        <AddIcon />
                    </Button></td>}
                </tr>
            </tfoot>
        </table>
    )
}
const SubjectShowToolbar = recordInit => {
    const record = useRecordContext() || recordInit;
    return (
        <TopToolbar>
            <ShowButton resource='exams' record={{ id: record?.exam_id }} label='resources.exams.name' />
            <EditButton variant='contained' label='resources.exams.action.add_result' />
        </TopToolbar>
    )
}

export const SubjectShow = () => {
    return (
        <Show actions={<SubjectShowToolbar />} title='resources.subjects.name'>
            <Stack direction="row" spacing={2}>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <TextField source="name" />
                    <ReferenceField source="exam_id" reference="exams" link='show' />
                    <ReferenceField source="session_id" reference="sessions" link={null} />
                    <ReferenceField source="class_id" reference="classes" link={null} />
                    <NumberField source="full_marks" />

                    <DateField source="start_at" {...CONSTANTS.timeStampOptions} />
                    <DateField source="end_at" {...CONSTANTS.timeStampOptions} />

                    <ReferenceField source="teacher_id" reference="employees" />
                    <BooleanField source="result_published" />
                    <BooleanField source="result_submitted" />
                </SimpleShowLayout>
                <SimpleShowLayout>
                    <ReferenceManyField reference="subjectresults" target="subject_id" label="resources.sessions.name">
                        <Datagrid bulkActionButtons={false}>
                            <ReferenceField source="student_id" reference="students" link={null} />
                            <TextField source="marks" />
                            <TextField source="grade" />
                            <TextField source="merit" />
                        </Datagrid>
                    </ReferenceManyField>
                </SimpleShowLayout>
            </Stack>
        </Show>
    )
}
export const SubjectEdit = props => {
    // const subject = useRecordContext()
    return <Edit redirect='show' mutationMode='pessimistic'>
        <SimpleForm >
            <TextInput source="name" disabled />
            <FunctionField
                label='resources.exams.fields.result_submitted'
                render={subject => <BooleanInput source="result_submitted" disabled={subject.result_submitted} />}
            />
            <ResultPublisher />
        </SimpleForm>
    </Edit>
}