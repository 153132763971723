import fetchJSON from "./fetcher";
import { AuthProvider } from 'react-admin';
// disable-line
import CONSTANTS from "../components/Constants.ts";
type Permission = {
    'employees' : {
        'list' : boolean,
        'create' : boolean,
        'update' : boolean,
        'delete' : boolean,
        'updateOfficial' : boolean,
        'updateOtherPassword' : boolean,
        'updatePermissions' : boolean,
        'updateOwn' : boolean,
        'createPayment' : boolean,
    },
    'transactions' : {
        'list' : boolean,
        'create' : boolean,
    },
    'payments' : {
        'list' : boolean,
        'create' : boolean,
        'pay' : boolean,
    },
    'students' : {
        'list' : boolean,
        'create' : boolean,
        'updatePersonal' : boolean,
        'updateOfficial' : boolean,
        'viewOfficial' : boolean,
        'viewPersonal' : boolean,
        'viewAll' : boolean,
    },
    'classes' : {
        'list' : boolean,
        'create' : boolean,
        'update' : boolean,
    },
    'sessions' : {
        'list' : boolean,
        'create' : boolean,
    },
    'exams' : {
        'list' : boolean,
        'create' : boolean,
        'update' : boolean,
        'updateResults' : boolean,
        'publishResults' : boolean,
    },
    'notices' : {
        'list' : boolean,
        'create' : boolean,
    },
    'attendance' : {
        'list' : boolean,
        'create' : boolean,
        'update' : boolean,
    },
    'sectors' : {
        'list' : boolean,
        'create' : boolean,
        'update' : boolean,
    },
    'logs' : {
        'list' : boolean,
        'create' : boolean,
    },
    'institutions' : {
        'list' : boolean,
        'create' : boolean,
        'update' : boolean,
    },
    'documents' : {
        'list' : boolean,
        'create' : boolean,
        'update' : boolean,
        'delete' : boolean,
    },
};
function getValueAtPath(path : string, obj : Permission) {
    const keys = path.split('.');
    let value = obj;
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (!value || typeof value !== 'object' || !value.hasOwnProperty(key))
            return false;
        value = value[key];

    }
    return value;
}
const authProvider : AuthProvider = {
    permissions: null,
    login: async ({ username, password }) => {
        const url = "/api/employees/login";
        try {
            const data = await fetchJSON(
                url,
                {
                    method: 'POST',
                    body: JSON.stringify({  username, password }),
                    headers: new Headers({ 'Content-Type': 'application/json' }),
                }
            );
            const token = data?.token;
            localStorage.setItem(CONSTANTS.TOKEN_KEY, token);
            const {data : user = null} = data;
            const role = user?.role;
            const id = user?.id;
            const fullName = user?.name;
            const avatar = user?.photograph_id && await fetchJSON(`/api/documents/${user?.photograph_id}`).then(({data}) => data?.url)
            const permissions = user?.permissions;
            const institutionSettings = user?.institution_settings;
            localStorage.setItem(CONSTANTS.LOCALE_KEY, institutionSettings?.settings?.type);
            localStorage.setItem(CONSTANTS.PERMISSION_KEY, JSON.stringify(permissions));
            authProvider.permissions = permissions;
            localStorage.setItem(CONSTANTS.USER_DETAILS_KEY, JSON.stringify({ id, fullName, avatar, role }));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    },
    logout: () => {
        localStorage.removeItem(CONSTANTS.TOKEN_KEY);
        localStorage.removeItem(CONSTANTS.USER_DETAILS_KEY);
        localStorage.removeItem(CONSTANTS.PERMISSION_KEY);
        localStorage.removeItem(CONSTANTS.CURRENT_INSTITUTION_KEY);
        localStorage.removeItem(CONSTANTS.INSTITUTION_MAP_KEY);
        localStorage.removeItem(CONSTANTS.LOCALE_KEY);
        authProvider.permissions = null;
        return Promise.resolve();
    },
    checkAuth: () => {
        const authenticated = localStorage.getItem(CONSTANTS.TOKEN_KEY)?.length > 0; 
        return authenticated ? Promise.resolve() : Promise.reject();
    },
    checkError: (err : Error) => {
        console.log("Checking error", err)
        return Promise.resolve();
    },
    getPermissions: ()  => {
        try {
            var permissions : Permission = null;
            if (authProvider.permissions)
                permissions = authProvider.permissions;
            else {
                const perms = localStorage.getItem(CONSTANTS.PERMISSION_KEY);
                if (perms && perms != "undefined")
                    permissions = JSON.parse(localStorage.getItem(CONSTANTS.PERMISSION_KEY));
                authProvider.permissions = permissions;
            }
            return Promise.resolve(permissions);
        } catch (err) {
            console.log(err);
            return Promise.reject(err);
        }
    },
    getIdentity: () => {
        try {
            const details = JSON.parse(localStorage.getItem(CONSTANTS.USER_DETAILS_KEY));
            const user = {
                id: details?.id,
                fullName: details?.fullName || null,
                avatar: details?.avatar || null,
                role: details?.role || null,
            };
            return Promise.resolve(user);
        } catch (err) {
            return Promise.reject();
        }
    },
    handleCallback: () => Promise.resolve(),
    handleLogin: () => Promise.resolve()
};
export default authProvider;