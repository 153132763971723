import { Datagrid, ImageField, ReferenceField, Button, ReferenceManyField, ShowButton, TabbedShowLayout, TextField, useRecordContext, useTranslate } from "react-admin";
import DownloadIcon from '@mui/icons-material/CloudDownload';
const DownloadButton = () => {
    const { id, url } = useRecordContext();
    const translate = useTranslate()
    return <Button
        component="a"
        variant='outlined'
        href={url}
        target="_blank"
        onClick={e => e.stopPropagation()}
        download
        label={'resources.documents.actions.download'}
        sx={{ marginRight: 1 }}
   ><DownloadIcon /></Button>
}
const DocumentTab = ({ target, ...props }) => (
    <TabbedShowLayout.Tab label='resources.documents.name' {...props}>
        <ReferenceManyField label='resources.documents.name' reference="documents" target={target}>
            <Datagrid>
                <TextField source="name" />
                <ImageField source="url" />
                <ReferenceField source="id" reference="documents" label="resources.documents.actions.download" link={false} >
                    <>
                        <ShowButton variant="outlined" />
                        <DownloadButton />
                    </>
                </ReferenceField>
            </Datagrid>
        </ReferenceManyField>
    </TabbedShowLayout.Tab>
)
export default DocumentTab;
