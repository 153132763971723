import {
    Create, Edit, List, Show,
    TextField,
    ArrayField, Datagrid, SimpleForm, TextInput,
    Button, DateInput,
    required,
    ReferenceField,
    ShowGuesser,
    TabbedShowLayout,
    ReferenceArrayField,
    ReferenceManyField,
    SimpleShowLayout,
    BooleanField,
    useRecordContext,
    useInput,
    useDataProvider,
    useArrayInput,
    useTranslate,
    Labeled,
    ShowButton,
    EditButton,
    WrapperField,
    useRedirect,
    FunctionField,
    ReferenceInput,
    SelectInput,
    usePermissions,
    LoadingPage,
    DateField,
    useRefresh,
    useUpdate,
    Confirm,
    useNotify,
    ArrayInput,
    NumberInput,
    SimpleFormIterator

} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PageviewIcon from '@mui/icons-material/Pageview';
import TerminateIcon from "@mui/icons-material/PowerOff"
import React from 'react';
import Stack from '@mui/material/Stack';
import { Forbidden, ROLE_ACCOUNTANT, ROLE_ADMIN, ROLE_SUPER_ADMIN } from '../providers/forbidden';
import DocumentTab from './documents/documentTab';
import CONSTANTS from '../components/Constants.ts';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
const AddSession = ({ record }) => {
    const { permissions, isLoading: permissionLoading } = usePermissions();
    const [sessions, setSessions] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(permissionLoading);
    const dataProvider = useDataProvider();
    React.useEffect(() => {
        if (record?.id) {
            setIsLoading(true);
            dataProvider.getList('sessions', {
                filter: { class_id: record.id },
            }).then(({ data }) => {
                setSessions([...data]);
                console.log(data)
                setIsLoading(false);
            });
        }
    }, []);

    return isLoading ? <LoadingPage /> : permissions?.sessions?.create && (
        <ArrayInput source="sessions" validate={required()} defaultValue={sessions} >
            <SimpleFormIterator inline disableReordering>
                <NumberInput source="id" style={{ display: 'none' }} />
                <TextInput source="name" helperText={false} />
                <DateInput source="start" validate={required()} />
                <DateInput source="end" />
            </SimpleFormIterator>
        </ArrayInput>
    )
}
/************************************EDIT*************************** */
export const ClassEdit = props => {
    const { permissions, isLoading } = usePermissions();
    return isLoading ? <LoadingPage /> : permissions?.classes?.update && (
        <Edit mutationMode='pessimistic' >
            <SimpleForm>
                <TextInput source="id" disabled />
                <TextInput source="name" validate={required()} /> <br />
                <ReferenceInput label='resources.classes.fields.teacher' source="teacher_id" reference='employees'>
                    <SelectInput optionText="name" validate={required()} />
                </ReferenceInput>

                <TextInput source="description" multiline />
                <FunctionField label="resources.sessions.name" render={record => <AddSession record={record} />} />
            </SimpleForm>
        </Edit>
    )
};
/************************************CREATE*************************** */
export const ClassCreate = () => {
    const { permissions, isLoading } = usePermissions();
    return isLoading ? <LoadingPage /> : (
        permissions?.classes?.create ? <Create>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="description" />
                <ReferenceInput source="teacher_id" reference='employees'>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <AddSession />
            </SimpleForm>
        </Create> : <Forbidden />
    )
}



/************************************LIST*************************** */
export const ClassList = () => {
    const { permissions, isLoading } = usePermissions();
    return isLoading ? <LoadingPage /> : (
        permissions?.classes?.list ? <List>
            <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="description" />
                <ReferenceField source="teacher_id" reference="employees" link={false}>
                    <TextField source="name" />
                </ReferenceField>
            </Datagrid>
        </List> : <Forbidden />
    );
}
/************************************SHOW*************************** */
const StartExamButton = () => {
    const translate = useTranslate();
    const { id: sessionID, class_id: classID } = useRecordContext();
    const redirect = useRedirect();
    const queryString = new URLSearchParams({ session_id: sessionID, class_id: classID });
    const onClick = (e) => redirect(`/exams/create?${queryString.toString()}`)
    return <Button label={translate('resources.exams.action.create')} onClick={onClick} variant='contained' color="success"><NoteAddIcon /></Button>
}
const EndSessionButton = () => {
    const translate = useTranslate();
    const { id: sessionID, active } = useRecordContext();
    const dataProvider = useDataProvider();
    if (!active) return null;
    const onClick = (e) => null;
    return <Button label={translate('resources.sessions.action.end')} onClick={onClick} variant='contained' color='error' ><TerminateIcon /></Button>
}
const ExamListButton = () => {
    const translate = useTranslate();
    const { id: sessionID, class_id: classID } = useRecordContext();
    const redirect = useRedirect();
    const onClick = (e) => redirect(`/classes/${classID}/show/2`);
    return <Button label={translate('resources.exams.name')} onClick={onClick} variant='contained' color='primary'><PageviewIcon /></Button>
}
const ResultPublishButton = (props) => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const notify = useNotify()
    const refresh = useRefresh();
    const [publishIntent, setPublishIntent] = React.useState(false);
    const [publishResult, { isLoading: resultPublishing }] = useUpdate('sessions');

    return (
        <>
            <Confirm
                title='resources.exams.action.publish'
                content='Sure You would publish'
                open={publishIntent}
                onConfirm={e => {
                    e.preventDefault()
                    console.log(record)
                    publishResult("sessions", { data: { id: record.id, result_published: true } }, {
                        onSuccess: () => { refresh(); setPublishIntent(false) },
                        onError: (err) => { notify(err.message, { type: 'error' }); setPublishIntent(false) }
                    })
                }}
                onClose={e => setPublishIntent(false)}
            />
            <Button
                label="resources.exams.action.publish"
                onClick={e => {
                    e.stopPropagation();
                    setPublishIntent(true);
                }}
                color="success"
                variant="contained"
                disabled={resultPublishing || publishIntent}
            ><HistoryEduIcon /></Button>
        </>
    )
}
const ResultButton = () => {
    const translate = useTranslate();
    const { id: examID, result_published } = useRecordContext();
    const redirect = useRedirect();
    const publishResult = (e) => e.stopPropagation() || redirect(`/exams/${examID}/1`);
    const showResult = (e) => e.stopPropagation() || redirect(`/exams/${examID}/show/1`);
    return (result_published ?
        <Button
            label={translate('resources.exams.action.publish')}
            onClick={publishResult}
            variant='contained'
            color='success'
        ><ReceiptLongIcon /></Button> : <Button
            label={translate('resources.exams.action.show_result')}
            onClick={showResult}
            variant='contained'
            color='secondary'
        ><PageviewIcon /></Button>
    );
}
const ResultListButton = () => {
    const redirect = useRedirect();
    const { id: sessionID, class_id: classID } = useRecordContext();
    const onClick = (e) => redirect(`/classes/${classID}/show/2`);
    return <Button label='resources.exams.action.add' onClick={onClick} variant='contained' color='primary'><PageviewIcon /></Button>
}
export const ClassShow = () => (
    <Show>
        <TabbedShowLayout>
            <TabbedShowLayout.Tab label="resources.classes.tabs.details">
                <Stack direction="row" spacing={2}>
                    <SimpleShowLayout>
                        <TextField source="id" />
                        <TextField source="name" />
                        <TextField source="description" />
                        <ReferenceField source="teacher_id" reference="employees" link={false}>
                            <TextField source="name" />
                        </ReferenceField>
                    </SimpleShowLayout>
                    <SimpleShowLayout>
                        <ReferenceManyField reference="sessions" target="class_id" label="resources.sessions.name">
                            <Datagrid bulkActionButtons={false}>
                                <TextField source="id" />
                                <TextField source="name" />
                                <TextField source="start" />
                                <TextField source="end" />
                                <FunctionField label="resources.sessions.name" render={record => (record?.active ? <>
                                    {record?.result_published ? <StartExamButton variant='contained' /> :
                                        record?.result_submit_due > 0 ? <ResultListButton /> : <ResultPublishButton variant='contained' />
                                    }
                                    <EndSessionButton variant='contained' />
                                </> : <ExamListButton variant='contained' />
                                )} />
                            </Datagrid>
                        </ReferenceManyField>
                    </SimpleShowLayout>
                </Stack>
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="resources.classes.tabs.students">
                <ReferenceManyField reference="students" target="class_id" label="resources.students.name">
                    <Datagrid>
                        <TextField source="id" />
                        <TextField source="name_bn" />
                        <TextField source="roll" />
                    </Datagrid>
                </ReferenceManyField>
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="resources.classes.tabs.exams">
                <ReferenceManyField reference="exams" target="class_id" label="resources.exams.name">
                    <Datagrid rowClick="show">
                        <TextField source="id" />
                        <TextField source="name" />
                        <DateField source="start" {...CONSTANTS.timeStampOptions} />
                        <DateField source="end" {...CONSTANTS.timeStampOptions} />
                        <WrapperField label="bn.labels.action">
                            <ResultButton variant='contained' />
                        </WrapperField>
                    </Datagrid>
                </ReferenceManyField>
            </TabbedShowLayout.Tab>
            <DocumentTab target="class_id" />
        </TabbedShowLayout>
    </Show >
);