
import { obj2FormData } from "./utils";
import CONSTANTS from "../components/Constants.ts";
//import { reportEvent, reportException, reportModal, reportTiming } from './analytics';
const baseURL = `${document.location.protocol}//${document.location.hostname}${process.env.NODE_ENV == "development" ? (process.env.API_PORT || ":8000") : ""}`;
const getTitle = url => url.replace(`${window.location.origin}/api/`, '').replace(/\.php.*/, '');
const jwtDecode = (jwt) => {
    try {
        const base64Url = jwt.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    } catch (e) {
        return null;
    }
}
const fetchJSON = (url, options) => {
    const now = window.performance.now();
    try {
        // if (typeof options == 'undefined')
        //     reportModal(getTitle(url));

        const fullURL = url;
        // if (localStorage.getItem(CONSTANTS.TOKEN_KEY)){
        //     fullURL.searchParams.append('jwt', localStorage.getItem(CONSTANTS.TOKEN_KEY));
        // }

        let headers = options?.headers;
        // Add the token to the headers if it exists
        if (localStorage.getItem(CONSTANTS.TOKEN_KEY))
            headers = {
                ...headers,
                Authorization: `Bearer ${localStorage.getItem(CONSTANTS.TOKEN_KEY)}`
            };
        let body = options?.body;
        const encoding = options?.encoding || 'json';
        if (body && body instanceof Object)
            if (encoding == 'formData') {
                body = obj2FormData(body);
            }
            else if (encoding == 'json') {
                console.log("JSON");
                body = JSON.stringify(body);
                headers = {
                    ...headers,
                    'Content-Type': 'application/json'
                };
            }
        if (encoding == 'formData')
            headers = {
                ...headers,
                'Content-Type': 'multipart/form-data'
            };
        else if (encoding == 'json')
            headers = {
                ...headers,
                'Content-Type': 'application/json'
            };
        options = {
            ...options,
            body,
            headers
        };
        return fetch(fullURL.toString(), options)
            .catch(err => {
                if (err.name == "TypeError") {
                    return Promise.reject({ message: "bn.errors.network_error" });
                }
                // If the response is unauthorized, remove the token and redirect to login
                if (err.status === 401) {
                    localStorage.removeItem(CONSTANTS.TOKEN_KEY);
                    window.location.href = '#/login';

                } else if (err.status === 500) {
                    return Promise.reject({ message: "bn.errors.internal_error" });
                }
                return Promise.reject(err);
            })
            .then(async res => {
                if(!res.ok){
                    if(res.status == 401){
                        localStorage.removeItem(CONSTANTS.TOKEN_KEY);
                        window.location.href = '#/login';
                        return Promise.reject({ message: "bn.errors.unauthorized" });
                    }
                    if(res.headers.get('content-type').includes('application/json')){
                        const json = await res.json()
                        return Promise.reject({ message: json['error'] });
                    }
                    var error = 'bn.errors.internal_error'
                    switch(res.status){
                        case 405:
                            error = 'bn.errors.method_not_allowed'
                            break;
                        case 406:
                            error = 'bn.errors.not_acceptable'
                            break;
                        case 408:
                            error = 'bn.errors.request_timeout'
                            break;
                        case 409:
                            error = 'bn.errors.conflict'
                        case 422:
                            error = 'bn.errors.unprocessable_entity'
                        default:
                            error = null
                    }
                    if(error)
                        return Promise.reject({ message: error });
                }
                return res;
            })//reportTiming(getTitle(url), window.performance.now() - now))
            .then(res => res.json()) // Transform the data into json
            .catch(err => typeof err == 'string' ? Promise.reject({ message: err }) : Promise.reject(err)) // Report the error
            .then(json => {
                // Extract the token from the response
                if (json?.token) {
                    localStorage.setItem(CONSTANTS.TOKEN_KEY, json.token);
                    const decoded = jwtDecode(json.token);
                    localStorage.setItem(CONSTANTS.CURRENT_INSTITUTION_KEY, decoded?.inst_id);
                }
                return json;
            })
            .then(res => (res?.detail || res?.error) ? Promise.reject({ message: (res?.detail || res?.error) }) : res); // Report the error
    } catch (err) {
        console.log(err);
        // reportException(err.message, true);
        return Promise.reject(err);
    }
};

export default fetchJSON;
