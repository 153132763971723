import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InstitutionIcon from '@mui/icons-material/Business';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Green from '@mui/material/colors/green';
import Red from '@mui/material/colors/red';
import Blue from '@mui/material/colors/blue';
import SchoolIcon from '@mui/icons-material/School';
import AddIcon from '@mui/icons-material/Add';
import LogIcon from '@mui/icons-material/History';
import ListIcon from '@mui/icons-material/List';
import BadgeIcon from '@mui/icons-material/Badge';
import PaymentIcon from "../assets/hand-money-icon.jsx"
import SectorIcon from "../assets/pocket-money.jsx"
import ExamIcon from "@mui/icons-material/HistoryEdu";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ShowIcon from '@mui/icons-material/Visibility';
import SMSIcon from '@mui/icons-material/Sms';
import { Title, useTranslate, LoadingIndicator, useDataProvider, useRedirect, usePermissions, useRefresh, RefreshIconButton, useGetOne, useGetList, Button, useAuthenticated, Loading, Link, useLogout } from 'react-admin';
import MuiButton from '@mui/material/Button'
import { Forbidden } from '../providers/forbidden';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from "@mui/material/useMediaQuery"
import CONSTANT from "./Constants.ts"
import PrintIcon from "@mui/icons-material/Print"
import NumToBangla from '../providers/number-to-words';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
// import Button from '@mui/material/Button';

import LoadingPage from './LoadingPage';
const DashboardExporterCss = `
td,th  {
    padding: 5px;
}
table {
    min-width : 400px;
}
fieldset {
    display: inline-block;
    page-break-inside:avoid;
}
    @media print {
        
        @page {
            size: A4 landscape;
            margin: 0;
        }
    }
`
const DashboardExporter = ({ translate, institution, dateFrom, dateTo, sectorMap }) => {
    const date1 = new Date(dateFrom);
    date1.setHours(0, 0, 0, 0);
    const date2 = new Date(dateTo);
    date2.setHours(23, 59, 59, 999);
    return (
        <div>
            <center>
                <img src={institution?.logo} style={{ height: '100px', width: '100px' }} />
                <h2>{institution?.name}</h2>
                <h3>{institution?.address}</h3>
                <h4>{translate('resources.dashboard.fields.date_from')} : {date1.toLocaleString('bn-BD', CONSTANT.timeStampOptions.options)}</h4>
                <h4>{translate('resources.dashboard.fields.date_to')} : {date2.toLocaleString('bn-BD', CONSTANT.timeStampOptions.options)}</h4>

                <h4>{translate('resources.dashboard.fields.total_balance')}
                    : {institution?.transaction?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                    &nbsp;({NumToBangla.convert(institution?.transaction?.amount)})
                </h4>
                <h4>{translate('resources.dashboard.fields.total_deposit')}
                    : {institution?.transaction?.deposit?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                    &nbsp;({NumToBangla.convert(institution?.transaction?.deposit?.amount)})
                </h4>
                <h4>{translate('resources.dashboard.fields.total_expense')}
                    : {institution?.transaction?.withdrawal?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                    &nbsp;({NumToBangla.convert(institution?.transaction?.withdrawal?.amount)})
                </h4>
                <h4>{translate('resources.dashboard.fields.payments_due')} : {institution?.payment?.due?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                    &nbsp; ({NumToBangla.convert(institution?.payment?.due?.amount)})
                </h4>
                <h4>{translate('resources.dashboard.fields.payments_waived')} : {institution?.payment?.waived?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                    &nbsp;({NumToBangla.convert(institution?.payment?.waived?.amount)})
                </h4>



                <fieldset>
                    <legend>{translate('resources.dashboard.fields.deposit')} </legend>
                    <table border="1" style={{ borderCollapse: 'collapse', textAlign: 'center' }}>
                        <thead>
                            <tr>
                                <th>{translate('resources.dashboard.fields.sector')}</th>
                                <th>{translate('resources.dashboard.fields.amount')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !!institution?.transaction?.deposit?.count && [...Object.keys(institution?.transaction?.deposit?.sectors)].map(sector => (
                                    <tr key={"deposit-tr" + sector}>
                                        <td>{sectorMap['' + sector]?.name} (#{sector})</td>
                                        <td>
                                            {institution?.transaction?.deposit?.sectors[sector]?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                                            ({NumToBangla.convert(institution?.transaction?.deposit?.sectors[sector]?.amount)})
                                        </td>

                                    </tr>
                                ))
                            }
                            <tr>
                                <th>{translate('resources.dashboard.fields.total')}</th>
                                <th>
                                    {institution?.transaction?.deposit?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                                    &nbsp;({NumToBangla.convert(institution?.transaction?.deposit?.amount)})
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
                <br />






                <fieldset>
                    <legend>{translate('resources.dashboard.fields.expense')}</legend>
                    <table border="1" style={{ borderCollapse: 'collapse', textAlign: 'center' }}>
                        <thead>
                            <tr>
                                <th>{translate('resources.dashboard.fields.sector')}</th>
                                <th>{translate('resources.dashboard.fields.amount')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !!institution?.transaction?.withdrawal?.count && [...Object.keys(institution?.transaction?.withdrawal?.sectors)].map(sector => (
                                    <tr key={"withdraw-" + sector}>
                                        <td>{sectorMap['' + sector]?.name} (#{sector})</td>
                                        <td>{institution?.transaction?.withdrawal?.sectors[sector]?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                                            : &nbsp;({NumToBangla.convert(institution?.transaction?.withdrawal?.amount)})
                                        </td>
                                    </tr>
                                ))
                            }
                            <tr>
                                <th>{translate('resources.dashboard.fields.total')}</th>
                                <th>
                                    {institution?.transaction?.withdrawal?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                                    &nbsp;({NumToBangla.convert(institution?.transaction?.withdrawal?.amount)})
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
                <br />




                <fieldset>
                    <legend>{translate('resources.dashboard.fields.payments_due')}
                    </legend>
                    <table border="1" style={{ borderCollapse: 'collapse', textAlign: 'center' }}>
                        <thead>
                            <tr>
                                <th>{translate('resources.dashboard.fields.sector')}</th>
                                <th>{translate('resources.dashboard.fields.amount')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !!institution?.payment?.due?.count && [...Object.keys(institution?.payment?.sectors)].map(sector => institution?.payment?.sectors[sector]?.due.amount > 0 && (
                                    <tr key={"due-" + sector}>
                                        <td>{sectorMap['' + sector]?.name} (#{sector})</td>
                                        <td>{institution?.payment?.sectors[sector]?.due.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                                            &nbsp; ({NumToBangla.convert(institution?.payment?.sectors[sector]?.due.amount)})
                                        </td>

                                    </tr>
                                ))
                            }
                            <tr>
                                <th>{translate('resources.dashboard.fields.total')}</th>
                                <th>
                                    {institution?.payment?.due?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                                    &nbsp;({NumToBangla.convert(institution?.payment?.due?.amount)})
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
                <br />







                <fieldset>
                    <legend>{translate('resources.dashboard.fields.payments_waived')}
                    </legend>
                    <table border="1" style={{ borderCollapse: 'collapse', textAlign: 'center' }}>
                        <thead>
                            <tr>
                                <th>{translate('resources.dashboard.fields.sector')}</th>
                                <th>{translate('resources.dashboard.fields.amount')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !!institution?.payment?.waived?.count && [...Object.keys(institution?.payment?.sectors)].map(sector => institution?.payment?.sectors[sector]?.waived.amount > 0 && (
                                    <tr key={"waive-" + sector}>
                                        <td>{sectorMap['' + sector]?.name} (#{sector})</td>
                                        <td>{institution?.payment?.sectors[sector]?.waived.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                                            &nbsp; ({NumToBangla.convert(institution?.payment?.sectors[sector]?.waived.amount)})
                                        </td>
                                    </tr>
                                ))
                            }
                            <tr>
                                <th>{translate('resources.dashboard.fields.total')}</th>
                                <th>
                                    {institution?.payment?.waived?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                                    &nbsp;({NumToBangla.convert(institution?.payment?.waived?.amount)})
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
            </center>
        </div>
    )
}
/*****************  Institution List  *****************/
const fetchInstitutionList = () => {
    const string = localStorage.getItem(CONSTANT.INSTITUTION_MAP_KEY);
    if (!string) return null;
    try {
        return JSON.parse(string);
    } catch (e) {
        return null;
    }
}
const cacheInstitutions = (orgs) => localStorage.setItem(CONSTANT.INSTITUTION_MAP_KEY, JSON.stringify(orgs));
const getCurrentSelectedInstitution = () => localStorage.getItem(CONSTANT.CURRENT_INSTITUTION_KEY) && parseInt(localStorage.getItem(CONSTANT.CURRENT_INSTITUTION_KEY)) || '';

/*****************  Institution List  *****************/

const ShortCut = ({ name = "ShortCut Name", icon: Icon = null, links = [] }) => {
    const translate = useTranslate('bn');
    const sx = {
        // marginTop: '10px',
    }
    return !!links?.length && <Paper elevation={1} style={{ margin: '10px' }} component='fieldset'>
        <Typography component='legend'>
            {<Icon sx={sx} />} {translate(name)}
        </Typography>
        {links.map(link => (
            <MuiButton startIcon={link.icon} LinkComponent={Link} to={link.link} sx={{ margin: '5px' }} variant='contained' key={link.link} color={link.color}>
                <Typography style={{ marginLeft: '5px' }} component='span'>
                    {translate(link.name)}
                </Typography>
            </MuiButton>
        ))}
    </Paper>
}
const ListCreateShortCut = ({ permissions, name, icon }) => {
    const links = []
    if (permissions[name]?.list)
        links.push({ name: 'resources.employees.permissions.' + name + '.list', link: '/' + name, icon: <ListIcon /> });
    if (permissions[name]?.create)
        links.push({ name: 'resources.employees.permissions.' + name + '.create', link: '/' + name + '/create', icon: <AddIcon />, color: 'success' });
    return <ShortCut
        name={'resources.' + name + '.name'}
        icon={icon}
        links={links}
    />
}
const ShowBalanceShortCut = ({ permissions }) => {
    const translate = useTranslate('bn');
    const [balanceOpen, setBalanceOpen] = React.useState(false);
    const [smsBalance, setSmsBalance] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const dataProvider = useDataProvider();
    useEffect(() => {
        if (!balanceOpen) return;
        setLoading(true);
        setError(null);
        dataProvider.getBalance("1").then(({ data: { balance } }) => {
            setSmsBalance(balance);
        }).catch(error => {
            console.error(error);
            setError(error);
        }).finally(() => {
            setLoading(false);
        })
    }, [balanceOpen])
    if (!permissions?.dashboard?.sms_balance)
        return null;
    return <Paper elevation={1} style={{ margin: '10px' }} component='fieldset'>
        <Typography component='legend'>
            <SMSIcon /> {translate('resources.dashboard.fields.sms_balance')}
        </Typography>
        <Dialog open={balanceOpen} onClose={e => setBalanceOpen(false)}>
            <DialogTitle>
                {translate('resources.dashboard.fields.sms_balance')}
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {loading ? <CircularProgress /> : error || translate('resources.dashboard.fields.sms_balance') + ' : ' + smsBalance}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={e => setBalanceOpen(false)} color='primary'>
                    <Typography style={{ marginLeft: '5px' }} component='span'>
                        {translate('ra.action.close')}
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
        <MuiButton startIcon={<ShowIcon />} onClick={e => setBalanceOpen(true)} sx={{ margin: '5px' }} variant='contained' color='primary'>
            <Typography style={{ marginLeft: '5px' }} component='span'>
                {translate('ra.action.show')}
            </Typography>
        </MuiButton>
    </Paper>
}

const Dashboard = props => {
    useAuthenticated();
    const logout = useLogout();
    const translate = useTranslate('bn');
    const dataProvider = useDataProvider();
    const { permissions, isLoading: permissionLoading } = usePermissions();
    const [loading, setLoading] = React.useState(false);
    const [institutionMap, setInstitutionMap] = React.useState(fetchInstitutionList());
    const [previousInst, setPreviousInst] = React.useState('');
    const [currentInstitution, setCurrentInstitution] = React.useState(getCurrentSelectedInstitution());
    const [dateFrom, setDateFrom] = React.useState(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
    const [dateTo, setDateTo] = React.useState(new Date().toISOString().split('T')[0]);
    const institutions = institutionMap && Object.values(institutionMap) || [];
    const currentName = (currentInstitution && institutionMap && (institutionMap[currentInstitution]?.name + " | ") || '') + translate('bn.name');
    document.title = currentName;






    const { data: institution, refetch: refetchInstitution, isLoading: institutionLoading } = useGetOne('institutions', {
        id: currentInstitution,
        meta: {
            filter: {
                date_from: dateFrom,
                date_to: dateTo,
                stat: true
            }
        }
    }, { cacheTime: 0 });

    const { data: sectors, refetch: refetchSectors, isLoading: sectorLoading } = useGetList('sectors', {}, { cacheTime: 0 })
    const sectorMap = sectors?.reduce((prev, n) => { prev[n.id] = n; return prev; }, {}) || {};

    React.useEffect(() => {
        if (!currentInstitution) return;
        refetchSectors();

        setLoading(true);

        dataProvider.getList('institutions', {
            filter: {
                stats: true
            }
        }).then(({ data }) => {
            const tempMap = {};
            data.forEach(institution => {
                tempMap[institution.id] = institution;
            });
            setInstitutionMap(tempMap);
            cacheInstitutions(tempMap);
            if (!currentInstitution) setCurrentInstitution(data[0]?.id);
        }).catch(error => {
            setCurrentInstitution(previousInst);
        }).finally(() => {
            setLoading(false);
        })
    }, [currentInstitution]);
    React.useEffect(() => {
        refetchInstitution({
            throwOnError: false
        })
    }, [dateFrom, dateTo]);
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return permissionLoading ? <Loading /> : <Card style={{ background: Blue[50] }}>
        <CardContent >
            <Title title={currentName} />

            <Box sx={{ display: 'flex', alignItems: 'flex-end', margin: '2px' }}>
                <InstitutionIcon />
                <Typography style={{ marginLeft: '10px' }}>
                    {translate('resources.dashboard.fields.institution')}
                </Typography>
                <Select
                    label={translate('resources.dashboard.fields.institution')}
                    onChange={e => {
                        setPreviousInst(currentInstitution)
                        setCurrentInstitution(e.target.value)
                    }}
                    style={{ marginLeft: '10px' }}
                    //defaultValue={currentInstitution}
                    variant='standard'
                    value={currentInstitution}
                >
                    {institutions?.map(
                        institution => (
                            <MenuItem
                                key={institution.id}
                                value={institution.id}
                                style={{ padding: '5px' }}
                            >
                                <b style={{ padding: '10px' }}>{institution.name}</b>
                            </MenuItem>
                        )
                    )}
                </Select>
                {loading || institutionLoading || sectorLoading ?
                    <CircularProgress sx={{ width: '30px', height: '30px' }} /> :
                    <Button
                        color="success"
                        label='resources.dashboard.fields.report'
                        variant='contained'
                        sx={{ ml: 1 }}
                        onClick={e => {
                            e.stopPropagation()
                            dataProvider.setPrintComponent(<DashboardExporter
                                translate={translate}
                                institution={institution}
                                dateFrom={dateFrom}
                                dateTo={dateTo}
                                sectorMap={sectorMap}
                            />, DashboardExporterCss)
                        }}
                    >
                        <PrintIcon />
                    </Button>
                }

            </Box>
            <Box>
                <label style={{ marginLeft: '10px' }}>
                    {translate('resources.dashboard.fields.date_from')}
                </label>
                <input
                    style={{ marginLeft: '10px' }}
                    min={CONSTANT.MINIMUM_DATE.toISOString().split('T')[0]}
                    max={new Date().toISOString().split('T')[0]}
                    type="date"
                    onChange={e => setDateFrom(e.target.value)}
                    value={dateFrom || ""}
                />
            </Box>
            <Box>
                <label style={{ marginLeft: '10px' }}>
                    {translate('resources.dashboard.fields.date_to')} :
                </label>
                <input
                    style={{ marginLeft: '10px' }}
                    max={new Date().toISOString().split('T')[0]}
                    type="date"
                    value={dateTo || ""}
                    onChange={e => setDateTo(e.target.value)}
                    min={CONSTANT.MINIMUM_DATE.toISOString().split('T')[0]}
                />
            </Box>
            <center>
                <picture style={{
                    maxWidth: isSmall ? 100 : 250
                }}>
                    {!!institution?.logo ? <img
                        style={{
                            maxWidth: isSmall ? 100 : 200
                        }}
                        src={institution?.logo}
                    /> : <img
                        src={(isSmall ? "/idarah128.png" : "/idarah192.png")}
                    />}
                    <figcaption style={{ fontSize: '20px' }}>
                        {institution?.name}
                    </figcaption>
                </picture>
            </center>
            {permissions?.dashboard.list && <Grid container spacing={3} marginTop={1}>
                <Paper elevation={1} style={{ margin: '20px' }}>
                    <Typography style={{ margin: '10px' }}>
                        {translate('resources.dashboard.fields.total_balance')} : {institution?.transaction?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                    </Typography>
                    <Typography style={{ margin: '10px' }}>
                        {translate('resources.dashboard.fields.total_deposit')} : {institution?.transaction?.deposit?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                    </Typography>
                    <Typography style={{ margin: '10px' }}>
                        {translate('resources.dashboard.fields.total_expense')} : {institution?.transaction?.withdrawal?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                    </Typography>
                </Paper>
                {/* <Paper elevation={1} style={{ margin: '20px', background: Blue[100] }}>
                    <Typography style={{ margin: '20px' }}>
                        {translate('resources.dashboard.fields.transaction_count')} : {statistics?.transaction?.count?.toLocaleString('bn-BD')}
                    </Typography>
                    {
                        !!statistics?.transaction?.count && [...Object.keys(statistics?.transaction?.deposit.sectors)].map(sector => (
                            <Typography style={{ margin: '10px' }}>
                                {sector} : <b style={{ color: Blue[800] }}>{(statistics?.transaction?.deposit.sectors[sector]?.amount + statistics?.transaction?.withdrawal.sectors[sector]?.amount)?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}</b>
                                &nbsp; ({(statistics?.transaction?.deposit.sectors[sector].count + statistics?.transaction?.withdrawal.sectors[sector].count).toLocaleString('bn-BD')})
                            </Typography>
                        ))

                    }
                </Paper> */}

                <Paper elevation={1} style={{ margin: '20px', background: Green[50] }}>
                    <Typography style={{ margin: '20px' }}>
                        {translate('resources.dashboard.fields.deposit')} : {institution?.transaction?.deposit?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                    </Typography>
                    {
                        !!institution?.transaction?.deposit?.count && [...Object.keys(institution?.transaction?.deposit?.sectors)].map(sector => (
                            <Typography style={{ margin: '10px' }} key={"deposit-tr" + sector}>
                                {sectorMap[sector]?.name} : <b style={{ color: Green[800] }} key={"deposit-" + sector}>{institution?.transaction?.deposit?.sectors[sector]?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}</b>
                                &nbsp; ({institution?.transaction?.deposit?.sectors[sector]?.count?.toLocaleString('bn-BD')})
                            </Typography>
                        ))
                    }
                </Paper>
                <Paper elevation={1} style={{ margin: '20px', backgroundColor: Red[50] }} >
                    <Typography style={{ margin: '20px' }}>
                        {translate('resources.dashboard.fields.expense')} : {institution?.transaction?.withdrawal?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                    </Typography>
                    {
                        !!institution?.transaction?.withdrawal?.count && [...Object.keys(institution?.transaction?.withdrawal?.sectors)].map(sector => (
                            <Typography style={{ margin: '10px' }} key={"withdraw-" + sector}>
                                {sectorMap[sector]?.name}  <b style={{ color: Red[800] }}>{institution?.transaction?.withdrawal?.sectors[sector]?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}</b>
                                &nbsp; ({institution?.transaction?.withdrawal?.sectors[sector]?.count?.toLocaleString('bn-BD')})
                            </Typography>
                        ))
                    }
                </Paper>
                <Paper elevation={1} style={{ margin: '20px', backgroundColor: Red[50] }}>
                    <Typography style={{ margin: '20px' }}>
                        {translate('resources.dashboard.fields.payments_due')} : {institution?.payment?.due?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                    </Typography>
                    {
                        !!institution?.payment?.due?.count && [...Object.keys(institution?.payment.sectors)].filter((sector_id) => institution.payment.sectors[sector_id].due.count).map(sector => (
                            <Typography style={{ margin: '10px' }} key={"due-" + sector}>
                                {sectorMap[sector]?.name} : <b style={{ color: Blue[800] }}>{institution?.payment?.sectors[sector]?.due.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}</b>
                                &nbsp; ({institution?.payment?.sectors[sector]?.due.count?.toLocaleString('bn-BD')})
                            </Typography>
                        ))
                    }
                </Paper>
                <Paper elevation={1} style={{ margin: '20px', backgroundColor: Red[50] }}>
                    <Typography style={{ margin: '20px' }}>
                        {translate('resources.dashboard.fields.payments_waived')} : {institution?.payment?.waived?.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}
                    </Typography>
                    {
                        !!institution?.payment?.waived?.count && [...Object.keys(institution?.payment.sectors)].filter((sector_id) => institution.payment.sectors[sector_id].waived.count).map(sector => (
                            <Typography style={{ margin: '10px' }} key={"due-" + sector}>
                                {sectorMap[sector]?.name} : <b style={{ color: Blue[800] }}>{institution?.payment?.sectors[sector]?.waived.amount?.toLocaleString('bn-BD', CONSTANT.currencyOptions.options)}</b>
                                &nbsp; ({institution?.payment?.sectors[sector]?.waived.count?.toLocaleString('bn-BD')})
                            </Typography>
                        ))
                    }
                </Paper>

            </Grid>
            }
            <Grid container spacing={3} marginTop={1}>
                <ListCreateShortCut permissions={permissions} name='students' icon={SchoolIcon} />
                <ListCreateShortCut permissions={permissions} name='employees' icon={BadgeIcon} />
                <ListCreateShortCut permissions={permissions} name='sectors' icon={SectorIcon} />
                <ListCreateShortCut permissions={permissions} name='transactions' icon={AttachMoneyIcon} />
                <ListCreateShortCut permissions={permissions} name='payments' icon={PaymentIcon} />
                <ListCreateShortCut permissions={permissions} name='exams' icon={ExamIcon} />
                <ListCreateShortCut permissions={permissions} name='logs' icon={LogIcon} />
                <ShowBalanceShortCut permissions={permissions} />
            </Grid>
        </CardContent>
    </Card>
}
export default Dashboard;