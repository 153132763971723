import polyglotI18nProvider from 'ra-i18n-polyglot';
import banglaMessages from 'ra-language-bangla';
import merge from 'lodash/merge';
import CONSTANTS from '../components/Constants';
const bnMadrasa = merge(banglaMessages, {
    resources: {
        dashboard: {
            name: 'ড্যাশবোর্ড',
            fields: {
                name: 'নাম',
                date_from: 'তারিখ থেকে',
                date_to: 'তারিখ পর্যন্ত',
                total: 'মোট',
                amount: 'পরিমাণ',
                sector: 'খাত',
                report: 'রিপোর্ট',
                total_balance: 'মোট ব্যালেন্স',
                total_deposit: 'মোট জমা',
                totalProfit: 'মোট লাভ',
                total_transactions: "মোট লেনদেন",
                total_expense: "মোট খরচ",
                total_orders: "মোট অর্ডার",
                total_loss: 'মোট ক্ষতি',
                net_income: 'নেট আয়',
                institution: "প্রতিষ্ঠান",
                deposit: "জমা",
                expense: "খরচ",
                payments_due: "বকেয়া",
                payments_waived: "প্রত্যাহার",
                sms_balance : "এসএমএস ব্যালেন্স",
            },
            reportFooter: {
                disclaimerDate: "",
                disclaimerElectronic: "",
                sponsoredBy: ""
            }
        },
        institutions: {
            name: "প্রতিষ্ঠান",
            fields: {
                list: "তালিকা",
                id: "আইডি",
                name: "নাম",
                address: "ঠিকানা",
                logo: "লোগো",
                settings: "সেটিংস",
                initial_account_name : "প্রাথমিক account নাম",
                initial_balance : "প্রাথমিক account ব্যালেন্স",
                initial_account_phone : "প্রাথমিক account ফোন",
                initial_account_password : "প্রাথমিক account পাসওয়ার্ড",
            },
            messages: {
                created: "প্রতিষ্ঠান সফলভাবে তৈরি হয়েছে"
            },
            settings: {
                settings: {
                    name: "সেটিংস",
                    type: {
                        name: "ধরন",
                        school: "স্কুল",
                        madrasa: "মাদ্রাসা",
                    },
                    active : "সক্রিয়",
                },
                features: {
                    name: "ফিচারস",
                    attendance: "হাজিরা",
                    exams: "পরীক্ষা",
                    sms: "এসএমএস",
                    backups: "ব্যাকআপ",
                }
            }
        },
        employees: {
            name: "কর্মচারী",
            fields: {
                list: "কর্মচারীগণ",
                id: "আইডি",
                name: "নাম",
                phone: "ফোন",
                photo: "ছবি",
                photograph_id : "ছবি",
                nid_no: "জাতীয় পরিচয় পত্র নং",
                permanent_address: "স্থায়ী ঠিকানা",
                present_address: "বর্তমান ঠিকানা",
                documents  : "নথিপত্র",
                mother : "মা",
                father : "বাবা",
                date_of_birth : "জন্ম তারিখ",
                accomodation : "আবাসন",
                blood_group : "রক্তের গ্রুপ",
                note : "মন্তব্য",

                salary: "বেতন",
                password: "পাসওয়ার্ড",
                email: "ইমেইল",
                due_amount: "বকেয়া টাকা",
                joined_at: "যোগদানের তারিখ",
                joining_date: "যোগদানের তারিখ",
                left_at: "পদত্যাগের তারিখ",
                leaving_date: "পদত্যাগের তারিখ",
                qualifications: "যোগ্যতা",
                leaving_reason: "পদত্যাগের কারন",
                title: "পদবী",
                part_time: "পার্ট টাইম",
                role: "অ্যাক্সেস লেভেল",
                previous_institutions: "পূর্বের প্রতিষ্ঠান",

                permissions: "অনুমতি",
            },
            tabs: {
                personal: "ব্যক্তিগত",
                official: "অফিসিয়াল",
                documents: "ডকুমেন্টস",
                transactions: "লেনদেন",
            },
            roles: {
                admin: "এডমিন",
                employee: "কর্মচারী",
                accountant: "হিসাবরক্ষক",
                teacher: "শিক্ষক",
                superAdmin: "সুপার এডমিন",
            },
            permissions: {
                attendance: {
                    list: "তালিকা",
                    create: "তৈরি",
                    update: "সম্পাদনা",
                },
                classes: {
                    list: "তালিকা",
                    create: "তৈরি",
                    update: "সম্পাদনা",
                },
                dashboard: {
                    list: "তালিকা",
                    sms_balance : "এসএমএস ব্যালেন্স দেখুন",
                },
                documents: {
                    list: "তালিকা",
                    create: "তৈরি",
                    update: "সম্পাদনা",
                    delete: "মুছুন",
                },
                employees: {
                    list: "তালিকা",
                    create: "তৈরি",
                    createPayment: "পেমেন্ট তৈরি",
                    delete: "মুছুন",
                    update: "সম্পাদনা",
                    updateOwn: "নিজের তথ্য সম্পাদনা",
                    updateOthers: "অন্যান্যের তথ্য সম্পাদনা",
                    updateOfficial: "অফিসিয়াল তথ্য সম্পাদনা",
                    updateOfficial :  "অফিসিয়াল তথ্য সম্পাদনা",
                    updateOtherPassword : "পাসওয়ার্ড সম্পাদনা",
                    updateOtherPassword: "পাসওয়ার্ড সম্পাদনা",
                    updatePermissions: "অ্যাক্সেস লেভেল সম্পাদনা",
                    viewOfficial : "অফিসিয়াল তথ্য দেখুন",
                    viewPersonal : "ব্যক্তিগত তথ্য দেখুন",
                    viewAll : "সকল তথ্য দেখুন",
                    updatePersonal : "ব্যক্তিগত তথ্য সম্পাদনা",
                },
                exams: {
                    list: "তালিকা",
                    create: "তৈরি",
                    update: "সম্পাদনা",
                    publishResults: "ফলাফল প্রকাশ",
                    updateResults: "ফলাফল সম্পাদনা",
                },
                institutions: {
                    list: "তালিকা",
                    create: "তৈরি",
                    update: "সম্পাদনা",
                    delete: "মুছুন",
                    restore : "পুনরুদ্ধার",
                    show : "দেখুন",
                    updateOthers : "অন্যান্যের তথ্য সম্পাদনা",
                },
                logs: {
                    list: "তালিকা",
                    create: "তৈরি",
                    show : "দেখুন",
                },
                notices: {
                    list: "তালিকা",
                    create: "তৈরি",
                },
                payments: {
                    list: "তালিকা",
                    create: "তৈরি",
                    update: "সম্পাদনা",
                    pay: "পরিশোধ",
                },
                sectors: {
                    list: "তালিকা",
                    create: "তৈরি",
                    update: "সম্পাদনা",

                },
                sessions: {
                    list: "তালিকা",
                    create: "তৈরি",
                    update: "সম্পাদনা",
                },
                students: {
                    list: "তালিকা",
                    create: "তৈরি",
                    update: "সম্পাদনা",
                    updateOfficial: "অফিসিয়াল তথ্য সম্পাদনা",
                    updatePersonal: "ব্যক্তিগত তথ্য সম্পাদনা",
                    viewAll: "সকল তথ্য দেখুন",
                    viewOfficial: "অফিসিয়াল তথ্য দেখুন",
                    viewPersonal: "ব্যক্তিগত তথ্য দেখুন",
                },
                transactions: {
                    list: "তালিকা",
                    create: "তৈরি",
                }

            },
            qualifications: {
                name: "যোগ্যতা",
                doctor: "ডাক্তার",
                hafez: "হাফিজ",
                alim: "আলিম",
                fazil: "ফাজিল",
                mawlana: "মাওলানা",
                mufti: "মুফতি",
                qari: "কারী",
                gatekeeper: "দারোয়ান",
                accountant: "হিসাবরক্ষক",
                cook: "বাবুর্চি",
                master: "মাস্টার",
                khadem: "খাদেম",
                engineer: "ইঞ্জিনিয়ার",
                professor: "প্রফেসর",
            },
        },
        attendance: {
            name: "হাজিরা",
        },
        payments: {
            name: "পেমেন্ট",
            fields: {
                list: "পেমেন্ট",
                status: "অবস্থা",
                sector_id: "খাত",
                student_id: "ছাত্র",
                employee_id: "কর্মচারী",
                transaction_id: "লেনদেন",
                id: "আইডি",
                amount: "পরিমাণ",
                created_at: "তারিখ",
                from_date: "তারিখ থেকে",
                to_date: "তারিখ পর্যন্ত",
                total_amount: "মোট পরিমাণ",
                waive_reason: "প্রত্যাহারের কারণ",
                registered_by: "নথিবদ্ধ করেছেন"
            },
            status: {
                due: "বকেয়া",
                paid: "পরিশোধিত",
                waived: "প্রত্যাহারিত",
            },
            action: {
                pay: "পরিশোধ",
                waive: "প্রত্যাহার করুন",
                view_transaction: "লেনদেন দেখুন",
                create: "বকেয়া তৈরি করুন",
            },
            tabs: {
                details: "বিস্তারিত",
            },
            messages: {
                bulk_create_confirm_title: "বকেয়া যোগ করতে চান?",
                bulk_create_confirm_message: "আপনি কি নিশ্চিত যে আপনি এই ব্যক্তিদের উপরে বকেয়া যোগ করতে চান?",
                waive_confirm_message: "আপনি কি নিশ্চিত যে আপনি এই বকেয়া প্রত্যাহার করতে চান?",
                create_success: 'বকেয়া সফলভাবে তৈরি হয়েছে',
                waived_success: 'বকেয়া প্রত্যাহার সফল হয়েছে',
            },

        },
        transactions: {
            name: "লেনদেন",
            fields: {
                list: "লেনদেন",
                id: "আইডি",
                date: "তারিখ",
                amount: "পরিমাণ",
                type: "ধরন",
                sector: "খাত",
                employee_id: "কর্মচারী",
                student_id: "ছাত্র",
                payment_method: "মাধ্যম",
                description: "বর্ণনা",
                initiated: "আরম্ভ",
                completed: "সম্পন্ন",
                cancelled: "বাতিল",
                status: "অবস্থা",
                deposit: "প্রতিষ্ঠানে জমা",
                expense: "খরচ",
                date_before: "পর্যন্ত",
                date_after: "থেকে",
                total_amount: "মোট পরিমাণ",
                note: "মন্তব্য",
                create: "লেনদেন তৈরি",
                withdraw: "প্রাতিষ্ঠানিক খরচ",
                payee: "প্রদানকারী",
                registered_by: "নথিবদ্ধ করেছেন",
                timestamp: "তারিখ",
                payment_id: "পেমেন্ট আইডি",
                sector_id: "খাত",
                payee_phone: 'প্রদানকারীর ফোন',
            },
            tabs: {
                details: "বিস্তারিত",
            }
        },
        sectors: {
            name: "খাত",
            fields: {
                employee_related: "কর্মচারী সম্পর্কিত",
                student_related: "ছাত্র সম্পর্কিত",
                id: "আইডি",
                name: "নাম",
                description: "বর্ণনা",
                deposit: "জমা?",
                related: "সম্পর্কিত",
                other: "অন্যান্য",
            },
            tabs: {
                details: "বিস্তারিত",
            }
        },
        students: {
            name: "ছাত্র",
            fields: {
                list: "তালিকা",
                id: "দাখিলা",
                name_bn: "নাম (বাংলা)",
                name_en: "নাম (ইংরেজি)",
                name_ar: "নাম (আরবি)",
                father: "বাবা",
                mother: "মা",
                guardian: "অভিভাবক",
                date_of_birth: "জন্ম তারিখ",
                permanent_address: "স্থায়ী ঠিকানা",
                present_address: "বর্তমান ঠিকানা",
                parent_phone_no: "মোবাইল নং",
                photograph_id: "ছবি",
                due_amount: "বকেয়া",
                paid_amount: "পরিশোধিত",
                payment_due: "বকেয়া",
                class_id: "জামাত",
                session_id: 'শিক্ষাবর্ষ',
                financial: "অর্থ অবস্থা",
                accomodation: "আবাসন",
                active: "বর্তমান শিক্ষার্থী",
                tution_fee: "টিউশন ফি",
                class: "জামাত",
                documents: "নথিপত্র",
                id_ref: "ছাত্রের পরিচিতিপত্র",
                parent_id_ref: "পিতামাতার পরিচিতিপত্র",
                inactive: "আগের শিক্ষার্থী",
                needGuardian: "অভিভাবক প্রয়োজন",
                guardian_phone: "অভিভাবকের ফোন",
                guardian_photo_id: "অভিভাবকের পরিচিতিপত্র",
                admission_date: "ভর্তির তারিখ",
                previous_institutions: "পূর্বের প্রতিষ্ঠান",
                inst_id: "প্রতিষ্ঠান আইডি",
                registered_by: "নথিবদ্ধ করেছেন",
                roll: "রোল",
                score: "স্কোর",
                merit: "মেধাস্থান",
                admission_form: "ভর্তি ফরম",
                grade: "গ্রেড",

                blood_group : "রক্তের গ্রুপ",
                note : "মন্তব্য",
                financials: {
                    orphan: "অনাথ",
                    poor: "দরিদ্র",
                    miskin: "মিস্কিন",
                    able: "সক্ষম",
                }
            },
            accomodations: {
                resident: "আবাসিক",
                non_resident: "অনাবাসিক",
            },
            tabs: {
                personal: "ব্যক্তিগত",
                academic: "শিক্ষাগত",
                documents: "নথিপত্র",
                results: "ফলাফল",
            },
            class_id: "জামাত",
        },
        documents: {
            name: "নথিপত্র",
            fields: {
                list: "তালিকা",
                id: "আইডি",
                name: "নাম",
                description: "বর্ণনা",
                file: "ফাইল",
                type: "ধরন",
                student_id: "ছাত্র",
                employee_id: "কর্মচারী",
                class_id: "জামাত",
                session_id: "শিক্ষাবর্ষ",
                transaction_id: "লেনদেন",
                preview: "প্রিভিউ",
                url: "ইউআরএল",
                role : "অ্যাক্সেস লেভেল",
            },
            actions: {
                upload: "আপলোড",
                download: "ডাউনলোড",
                preview: "প্রিভিউ",
            },
            roles : {
                private : "ব্যক্তিগত",
                public : "পাবলিক",
            }
        },
        classes: {
            name: "জামাত",
            fields: {
                list: "তালিকা",
                id: "আইডি",
                name: "নাম",
                description: "বর্ণনা",
                students: "ছাত্র",
                teachers: "শিক্ষক",
                sessions: "শিক্ষাবর্ষ",
                documents: "নথিপত্র",
                active: "সক্রিয়",
                inactive: "নিষ্ক্রিয়",
                teacher_id: "শিক্ষক",
            },
            tabs: {
                details: "বিস্তারিত",
                students: "ছাত্র",
                teachers: "শিক্ষক",
                documents: "নথিপত্র",
                exams: "পরীক্ষা",
            }
        },
        sessions: {
            name: "শিক্ষাবর্ষ",
            fields: {
                list: "তালিকা",
                id: "আইডি",
                name: "নাম",
                description: "বর্ণনা",
                students: "ছাত্র",
                teachers: "শিক্ষক",
                classes: "জামাত",
                documents: "নথিপত্র",
                start: "শুরু",
                end: "শেষ",
                teacher_id: "শিক্ষক",
            },
            action: {
                start: "শুরু",
                end: "শেষ করুন",
                add: "যোগ করুন",
            },
            tabs: {
                details: "বিস্তারিত",
                students: "ছাত্র",
                teachers: "শিক্ষক",
                classes: "জামাত",
                documents: "নথিপত্র",
            }
        },
        exams: {
            name: "পরীক্ষা",
            fields: {
                list: "তালিকা",
                id: "আইডি",
                name: "নাম",
                description: "বর্ণনা",
                students: "ছাত্র",
                teachers: "শিক্ষক",
                class_id: "জামাত",
                session_id: "শিক্ষাবর্ষ",
                documents: "নথিপত্র",
                start: "শুরু",
                end: "শেষ",
                result_published: "ফলাফল প্রকাশিত?",
                result_submitted: "ফলাফল জমা দেওয়া হয়েছে?",
                merit: "মেধাস্থান",
                results: "ফলাফল",
            },
            action: {
                create: "পরীক্ষা তৈরি করুন",
                edit: "পরীক্ষা সম্পাদনা করুন",
                publish: "ফলাফল প্রকাশ করুন",
                show_result: "ফলাফল দেখুন",
                add_result: "ফলাফল যোগ করুন",
            },
            tabs: {
                details: "বিস্তারিত",
                students: "ছাত্র",
                teachers: "শিক্ষক",
                classes: "জামাত",
                documents: "নথিপত্র",
                results: "ফলাফল",
                subjects: "বিষয়",
            }
        },
        subjects : {
            name: "বিষয়",
            fields: {
                list: "তালিকা",
                id: "আইডি",
                name: "নাম",
                description: "বর্ণনা",
                class_id: "জামাত",
                documents: "নথিপত্র",
                teacher_id: "শিক্ষক",
                full_marks : "পূর্ণ নম্বর",
                start_at : "শুরু",
                end_at : "শেষ",
                result_submitted : "ফলাফল জমা দেওয়া হয়েছে?",
                session_id : "শিক্ষাবর্ষ",
                exam_id : "পরীক্ষা",
                result_published : "ফলাফল প্রকাশিত?",
            },
        },
        results: {
            name: "ফলাফল",
            fields: {
                list: "তালিকা",
                id: "আইডি",
                exam_id: "পরীক্ষা",
                student_id: "ছাত্র",
                marks: "নম্বর",
                total_marks : "মোট নম্বর",
                grade: "গ্রেড",
                average : "গড়",
                remarks: "মন্তব্য",
                date_from: "তারিখ থেকে",
                merit: "মেধাস্থান",
                date_to: "তারিখ পর্যন্ত",
                session_id: "শিক্ষাবর্ষ",
            },
        },
        notices: {
            name: "নোটিশ",
            fields: {
                list: "তালিকা",
                id: "আইডি",
                title: "শিরোনাম",
                content: "বিস্তারিত",
                document_id: "নথি",
                timestamp: "সময়",
                related_notice: "সম্পর্কিত নোটিশ",
            }
        },
        logs: {
            name: "লগ",
            fields: {
                list: "তালিকা",
                id: "আইডি",
                user_id: "ব্যবহারকারী",
                action: "কর্ম",
                timestamp: "সময়",
                ip_address: "আইপি",
                inst_id: "প্রতিষ্ঠান",
                performed_by: "কর্মচারী",
                note: "মন্তব্য",
            },
            actions: {
                login: "প্রবেশ করেছেন",
                login_failed: "প্রবেশ ব্যর্থ হয়েছে",
                employee_created: "কর্মচারী তৈরি করেছেন",
                employee_creation_failed: "কর্মচারী তৈরি ব্যর্থ হয়েছে",
                employee_update: "কর্মচারী সম্পাদনা করেছেন",
                employee_update_failed: "কর্মচারী সম্পাদনা ব্যর্থ হয়েছে",

                exam_created: "পরীক্ষা তৈরি করেছেন",
                exam_create_failed: "পরীক্ষা তৈরি ব্যর্থ হয়েছে",
                exam_updated: "পরীক্ষা সম্পাদনা করেছেন",
                exam_update_failed: "পরীক্ষা সম্পাদনা ব্যর্থ হয়েছে",
                exam_result_published: "পরীক্ষার ফলাফল প্রকাশ করেছেন",
                exam_result_publish_failed: "পরীক্ষার ফলাফল প্রকাশ ব্যর্থ হয়েছে",
                exam_result_submitted: "পরীক্ষার ফলাফল জমা দিয়েছেন",
                exam_result_submit_failed: "পরীক্ষার ফলাফল জমা দেওয়া ব্যর্থ হয়েছে",
                logo_created : "লোগো তৈরি করেছেন",
                institution_created : "প্রতিষ্ঠান তৈরি করেছেন",
            }
        },
    },
    bn: {
        name: "ইদারাহ",
        labels: {
            'id': "আইডি",
            'name_bn': "নাম",
            'email': "ইমেইল",
            'phone': "ফোন",
            'permanent_address': "স্থায়ী ঠিকানা",
            'present_address': "বর্তমান ঠিকানা",
            'mother_name_bn': "মা",
            'father_name_bn': "বাবা",
            'legal_guardian_bn': "অভিভাবক",
            'birthday': "জন্ম তারিখ",
            'photo_id': "ছবি",
            'registered': "নিবন্ধন তারিখ",
            'registered_by': "নিবন্ধন করেছেন",
            'motherPhone': "মাতার ফোন",
            'fatherPhone': "বাবার ফোন",
            'legal_guardianPhone': "অভিভাবকের ফোন",
            'accomodation': "বাসস্থান",
            'class_roll': "শ্রেণী রোল",
            'payment_due': "পরিশোধ বাকি",
            class_name: "জামাত",
            'approved': "অনুমোদিত",
            legal_guardian_address: "অভিভাবকের ঠিকানা",
            resident: "আবাসিক",
            non_resident: "অনাবাসিক",
            click_to_upload: "আপলোড করুন",
            summary: "সারাংশ",
            short_id: "শর্ট আইডি",
            action: "কর্ম",
            install: "ইন্সটল",
        },
        errors: {
            access_denied: "এক্সেস নেই",
            role_invalid: "এক্সেস লেভেল অবৈধ",
            role_required: "এক্সেস লেভেল প্রয়োজন",
            amount_required: "পরিমাণ প্রয়োজন",
            birthdate_required: "জন্ম তারিখ প্রয়োজন",
            cannot_update_other_password: 'অন্য কারো পাসওয়ার্ড পরিবর্তন সম্ভব নয়',
            cannot_update_self: 'নিজের তথ্য পরিবর্তন সম্ভব নয়ু',
            class_id_cannot_be_changed: 'জামাত আইডি পরিবর্তন সম্ভব নয়',
            class_not_found: "জামাত পাওয়া যায়নি",
            class_required: 'জামাত প্রয়োজন',
            content_or_document_required: 'বিষয়বস্তু বা নথি প্রয়োজন',
            document_not_found: 'নথি পাওয়া যায়নি',
            employee_id_cannot_be_changed: 'কর্মচারী আইডি পরিবর্তন করা যাবে না',
            employee_not_found: 'কর্মচারী পাওয়া যায়নি',
            end_not_within_session: 'The end date of the exam is not within the session\'s start and end dates.',
            end_required: 'The \'end\' field is required for creating an exam.',
            exam_id_required: "The exam_id parameter is required.",
            exam_not_found: 'The specified exam_id was not found.',
            fathers_name_required: "পিতার নাম প্রয়োজন",
            file_required: 'কোন ফাইল পাওয়া যায়নি',
            id_document_not_found: 'পরিচয় প্রদানকারী নথি পাওয়া যায়নি',
            id_must_be_null: 'আইডি নাম্বার প্রয়োজন নেই',
            id_reference_required: "পরিচয়কারী নথি প্রয়োজন",
            institution_not_found: 'প্রতিষ্ঠান পাওয়া যায়নি',
            invalid_role: 'অবৈধ এক্সেস লেভেল',
            invalid_credentials: "ইমেইল বা পাসওয়ার্ড ভুল",
            invalid_email: "ইমেইল ভুল",
            invalid_format: "Invalid format.",
            invalid_payment_status: "অবৈধ পেমেন্ট স্ট্যাটাস",
            invalid_phone: "ফোন নম্বর ভুল",
            internal_error: "সার্ভারে ঝামেলা হয়েছে। কিছুক্ষন পরে আবার চেষ্টা করুন।",
            method_not_allowed: 'কাজটি করা যাবে না',
            missing_data: 'কিছু তথ্য পাওয়া যায়নি',
            mothers_name_required: "মায়ের নাম প্রয়োজন",
            name_required: 'নাম প্রয়োজন',
            network_error: 'নেটওয়ার্ক এরর',
            nid_document_required: 'জাতীয় পরিচয় প্রদানকারী নথি প্রয়োজন',
            nid_no_required: 'জাতীয় পরিচয় নং প্রয়োজন',
            no_access_to_institution: 'প্রতিষ্ঠানের অ্যাক্সেস নেই',
            no_data: 'কোনো তথ্য পাওয়া যায়নি',
            no_results_found: 'কোনো ফলাফল পাওয়া যায়নি',
            notice_not_found: 'নোটিশ পাওয়া যায়নি',

            parents_phone_number_required: "মা বা বাবার ফোন নম্বর প্রয়োজন",
            password_required: "পাসওয়ার্ড প্রয়োজন",
            payment_id_cannot_be_changed: 'বকেয়ার আইডি পরিবর্তন করা যাবে না',
            payment_not_due: 'বকেয়া পরিশোধের প্রয়োজন নেই',
            payment_not_found: 'বকেয়া পাওয়া যায়নি',
            payment_ids_required: "বকেয়ার আইডি প্রয়োজন",
            permanent_address_required: "স্থায়ী ঠিকানা প্রয়োজন",
            photograph_not_found: 'ছবি পাওয়া যায়নি',
            photograph_required: "ছবি প্রয়োজন",
            present_address_required: 'বর্তমান ঠিকানা প্রয়োজন',
            previous_institutions_required: 'পূর্ববর্তী প্রতিষ্ঠানের তথ্য প্রয়োজন',
            related_notice_not_found: 'সম্পর্কিত নোটিশ পাওয়া যায়নি',
            result_already_published: "ফলাফল ইতিমধ্যেই প্রকাশিত হয়ে গেছে",
            result_not_found: "ফলাফল পাওয়া যায়নি",
            result_publish_cannot_be_false: 'ফলাফল একবার প্রকাশের পর সেটা প্রত্যাহার করা যাবে না',
            result_submit_due: "সকল পরীক্ষার ফলাফল এখনো জমা হয় নি",
            result_submit_cannot_be_false: "ফলাফল একবার জমা দেওয়ার পর সেটা প্রত্যাহার করা যাবে না",
            results_required: "ফলাফল প্রয়োজন",
            sector_id_required: "খাত আইডি প্রয়োজন",
            sector_not_found: "খাত পাওয়া যায় নি",
            session_class_mismatch: 'শিক্ষাবর্ষ এবং জামাত মিলছে না',
            session_not_found: "শিক্ষাবর্ষ পাওয়া যায় নি",
            session_required: 'শিক্ষাবর্ষ প্রয়োজন',
            session_start_after_end: 'শিক্ষাবর্ষের শুরুর তারিখ শেষের তারিখের পরে হতে পারবে না',
            start_after_end: 'পরীক্ষার শুরুর তারিখ শেষের তারিখের পরে হতে পারবে না',
            start_not_within_session: 'পরীক্ষার শুরুর তারিখ শিক্ষাবর্ষের মধ্যে হতে হবে',
            start_required: 'পরীক্ষার শুরুর তারিখ প্রয়োজন',
            student_id_cannot_be_changed: 'শিক্ষার্থী আইডি পরিবর্তন করা যাবে না',
            student_id_document_cannot_be_deleted: 'শিক্ষার্থী আইডি প্রমাণপত্র মুছে ফেলা যাবে না',
            student_not_found: 'শিক্ষার্থী পাওয়া যায় নি',
            title_required: 'পদবি প্রয়োজন',
            transaction_id_cannot_be_changed: 'লেনদেন আইডি পরিবর্তন করা যাবে না',
            transaction_not_found: 'লেনদেন পাওয়া যায় নি',
            upload_failed: 'আপলোড ব্যর্থ হয়েছে',
            username_required: 'ব্যবহারকারীর নাম প্রয়োজন',
            session_expired: "পুনরায় লগিন করুন",
            inactive_employee: "কর্মচারী বা প্রতিষ্ঠানটি নিষ্ক্রিয় করা হয়েছে",
            cannot_delete_own_institution: "নিজের প্রতিষ্ঠান মুছে ফেলা যাবে না",
        }
    }
});
const bnSchool = merge(bnMadrasa, {
    resources: {
        students: {
            fields: {
                id: "রেজি নং",
                class_id: "শ্রেণী",
            }
        },
        classes: {
            name: "শ্রেণী",
        }
    }
});
const language = localStorage.getItem(CONSTANTS.LOCALE_KEY);
const translationProvider = polyglotI18nProvider(locale => language == 'madrasa' ? bnMadrasa : bnSchool, 'madrasa');
export default translationProvider