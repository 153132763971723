
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IdarahLogo from '../assets/idarah512.png';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
import WelcomeArabic from '../assets/welcome-ar.jsx';
const LoadingPage = ({skeleton = true, WelcomeText }) => {
    const isSmall = useMediaQuery('(max-width:600px)');
    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                p: 2,
                textAlign: 'center',
            }}
            elevation={0}
        >
            <Box sx={{ m: 0, position: 'relative' }}>
                <img src={IdarahLogo} alt="Idarah Logo" style={{ width: isSmall ? '60%' : '35%', height: 'auto', zIndex:0 }} />
                <br/>
                <CircularProgress
                    size={30}
                    sx={{
                        color: 'green',
                        // position: 'relative',
                        zIndex: 1,
                        // marginTop: '-12px',
                        // marginLeft: '-12px',
                    }}
                />
            </Box>
            <Typography variant="h4" sx={{ mt: 2 }}>
                ইদারাতুল মাদরাসা
            </Typography>
            <Typography variant="h5" sx={{ mt: 2 }}>
                <WelcomeArabic />
            </Typography>
            <Typography variant="p" sx={{ mt: 2, color: 'red', fontSize: '110%' }}>
                যদি আপনি অনেকক্ষণ ধরে এই পৃষ্ঠাটি দেখেন, তাহলে অনুগ্রহপূর্বক নিচের <b>"রিফ্রেশ"</b> বোতামটি চেপে পেজটি রিফ্রেশ করুন।
            </Typography>
            <Button
                variant="contained"
                sx={{ mt: 2, borderRadius: 10 }}
                onClick={() => window.location.reload()}
                color="success"
                size='large'
                startIcon={<RefreshIcon />}
            >
                রিফ্রেশ
            </Button>
            <Typography variant="p" sx={{ position: 'absolute', top: 'auto', bottom : '10%', color: 'black', fontSize: '110%' }}>
                কোনো প্রকার সমস্যা থাকলে আমাদের সাথে যোগাযোগ করুন <a href="mailto:support@rangdhanu.pw">support@rangdhanu.pw</a> এই ঠিকানায়।
            </Typography>
        </Paper>
    );
}
export default LoadingPage;