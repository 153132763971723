import React from 'react';
import {
    List, Datagrid, TextField, DateField, EditButton,
    Edit, SimpleForm, TextInput, DateInput, Create,
    Filter, ReferenceInput, SelectInput, ReferenceField,
    Show, SimpleShowLayout,
    Delete, NumberField, NumberInput,
    DeleteButton, maxValue, minValue,
    ListButton, TopToolbar,
    DateTimeInput, ExportButton,
    BooleanInput, useQueryWithStore,
    BooleanField, required, RichTextField, CreateButton, useTranslate, TabbedShowLayout, usePermissions, NullableBooleanInput, useDataProvider, useNotify, FunctionField, ChipField, Labeled, SingleFieldList, useRedirect, useRecordContext, WrapperField, Button, Confirm, useListContext, useUnselectAll
} from 'react-admin';
import { Forbidden, Box, Typography, Red as RED, GREEN, ROLE_ACCOUNTANT, ROLE_ADMIN, ROLE_SUPER_ADMIN, SelectField } from '../../providers/forbidden';
import NumToBangla from '../../providers/number-to-words';
import DocumentTab from '../documents/documentTab';
import AddMoreImage from '../imageInput';
import ShowIcon from '@mui/icons-material/Visibility';
import AttachMoneyIcon from '@mui/icons-material/AttachMoneyOutlined';
import MoneySlashIcon from '@mui/icons-material/MoneyOffOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MuiTextInput from '@mui/material/TextField';
import CONSTANTS from '../Constants';


const PaymentCreateToolbar = props => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);
export const PaymentCreate_ = () => {
    const [associatedWithStudent, setAssociatedWithStudent] = React.useState(false);
    const [associatedWithEmployee, setAssociatedWithEmployee] = React.useState(false);
    const [misc, setMisc] = React.useState(false);
    const [isDeposit, setIsDeposit] = React.useState(null);
    const [payeeName, setPayeeName] = React.useState('');
    const [studentID, setStudentID] = React.useState(null);
    const [employeeID, setEmployeeID] = React.useState(null);
    const [amount, setAmount] = React.useState(null);
    const [amountFixed, setAmountFixed] = React.useState(false);
    const [paymentID, setPaymentID] = React.useState(null);
    const [sectorID, setSectorID] = React.useState(null);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    // Fetching payment info and auto filling the form
    // Fetching student info and auto filling the form
    return (
        1 ? <Create actions={<PaymentCreateToolbar />}>
            <SimpleForm>
                {/* <SelectInput
                    source="deposit"
                    choices={[
                        { id: true, name: 'resources.payments.fields.deposit' },
                        { id: false, name: 'resources.payments.fields.withdraw' },
                    ]}
                    label="resources.payments.fields.type"
                    validate={required()}
                    onChange={e => setIsDeposit(e.target.value)}
                /> */}
                <SelectInput
                    source="related"
                    choices={[
                        { id: 'student', name: 'resources.sectors.fields.student_related' },
                        { id: 'employee', name: 'resources.sectors.fields.employee_related' },
                        { id: 'other', name: 'resources.sectors.fields.other' },
                    ]}
                    onChange={e => {
                        setAssociatedWithEmployee(e.target.value == 'employee');
                        setAssociatedWithStudent(e.target.value == 'student');
                        setMisc(e.target.value == 'other');
                    }}
                    label="resources.sectors.fields.related"
                    validate={required()}
                />
                <ReferenceInput source="sector_id" filter={{
                    ...(associatedWithStudent ? { student_related: true } : {}),
                    ...(associatedWithEmployee ? { employee_related: true } : {}),
                    ...(misc ? { misc: true } : {})
                }} reference="sectors" label="resources.sectors.name" >
                    <SelectInput optionText="name" validate={required()} />
                </ReferenceInput>
                {associatedWithStudent && (
                    <ReferenceInput source="student_id" reference="students" label="resources.students.name" >
                        <SelectInput optionText="name_bn" validate={required()} onChange={e => setStudentID(e.target.value)} />
                    </ReferenceInput>
                )}
                {associatedWithEmployee && (
                    <ReferenceInput source="employee_id" reference="employees" label="resources.employees.name" >
                        <SelectInput optionText="name" validate={required()} onChange={e => setEmployeeID(e.target.value)} />
                    </ReferenceInput>
                )}
                <TextInput
                    validate={required()}
                    source="amount"
                    label="resources.payments.fields.amount"
                    inputMode='decimal'
                    type='number'
                    onChange={e => !amountFixed && setAmount(e.target.value)}
                    value={amount}
                />
                <AddMoreImage multiple source="documents" name="Payment Documents" />
            </SimpleForm>
        </Create> : <Forbidden />
    )
}
/////////////////////////////////////////// SHOW ///////////////////////////////////////////
const ExecuteButton = (record) => {
    const redirect = useRedirect();
    const [waiveIntent, setWaiveIntent] = React.useState(false);
    const [waiving, setWaiving] = React.useState(false);
    const [waiveReason, setWaiveReason] = React.useState('');
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const translate = useTranslate();
    if(!record) return null;
    const { id: paymentID, transaction_id, sector_id: sectorID, deposit, amount : waiveAmount, student_id: studentID, employee_id: employeeID, status } = record;
    const pay = e => {
        e.stopPropagation();
        const queryParams = new URLSearchParams({
            payment_id: paymentID,
            sector_id: sectorID,
            amount: waiveAmount,

        });
        if (studentID)
            queryParams.append('student_id', studentID);
        if (employeeID)
            queryParams.append('employee_id', employeeID);
        if (deposit)
            queryParams.append('deposit', true);
        redirect(`/transactions/create?${queryParams.toString()}`)
    }
    const viewTransactions = e => {
        e.stopPropagation();
        redirect('show', 'transactions', transaction_id)
    }
    const waive = e => {
        e.stopPropagation();
        setWaiving(true);
        dataProvider.updateMany('payments', { ids: [paymentID], data: { status: 'waived', amount: waiveAmount, note: waiveReason } })
            .then(({ data }) => {
                setWaiving(false);
                setWaiveIntent(false);
                notify('resources.payments.messages.waived_success', { type: 'success' });
            })
            .catch(({ message }) => {
                notify(message, { type: 'error' });
                setWaiving(false);
            })
    }
    const cancelwaive = e => {
        e.stopPropagation();

    }
    const results = [];
    if (status == 'due') {
        results.push(<Button key='pay'  color='success' sx={{ marginRight: '5px' }} variant='contained' label="resources.payments.action.pay" onClick={pay}><AttachMoneyIcon variant="contained" /></Button>);
        results.push(<Button key='waive' label="resources.payments.action.waive" onClick={e => {
            e.stopPropagation();
            setWaiveIntent(true);
        }
        } color="error" variant='contained' ><MoneySlashIcon variant="contained" /></Button>)
    } else if (transaction_id) {
        results.push(<Button key='view'  variant='contained' label="resources.payments.action.view_transaction" onClick={viewTransactions} ><ShowIcon variant="contained" /></Button>)
    }
    results.push(
        <Confirm
            title={translate('resources.payments.action.waive')}
            content={<>
                <Typography variant="body1" component="p" key="message">
                    {translate('resources.payments.messages.waive_confirm_message')}
                </Typography>
                <MuiTextInput
                    label={translate('resources.payments.fields.waive_reason')}
                    value={waiveReason}
                    onChange={e => setWaiveReason(e.target.value)}
                    error={waiveReason === ''}
                    multiline
                    key="waive_reason"
                />
            </>}
            confirmColor="warning"
            open={waiveIntent}
            onConfirm={waive}
            onClose={() => setWaiveIntent(false)}
            key="confirm"
        />
    )
    return <>
        {results}
    </>

}
const ShowLayoutToolbar = ({ basePath, data, resource }) => {
    const record = useRecordContext();
    return <TopToolbar>
        {ExecuteButton(record)}
        <CreateButton key="create" />
        <ListButton key="list" />
    </TopToolbar>
}
export const PaymentShow = props => {
    const { permissions, isLoading: permissionLoading } = usePermissions()
    return !permissionLoading ? (
        <Show actions={<ShowLayoutToolbar />}>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="resources.payments.tabs.details">
                    <NumberField source="id" {...CONSTANTS.locales} />
                    <NumberField source="amount" {...CONSTANTS.currencyOptions} />
                    <SelectField
                        choices={CONSTANTS.paymentStatus}
                        source="status"
                        resource="payments"
                        {...CONSTANTS.locales}
                    >
                        <ChipField source="name" />
                    </SelectField>
                    <ReferenceField source="sector_id" reference="sectors" link={false}>
                        <TextField source="name" />
                    </ReferenceField>

                    <FunctionField render={record => (
                        <>
                            {record?.student_id && (
                                <Labeled>
                                    <ReferenceField label="resources.students.name" source="student_id" reference="students" link="show">
                                        <TextField source="name_bn" />
                                    </ReferenceField>
                                </Labeled>
                            )}
                            {record?.employee_id && (
                                <Labeled>
                                    <ReferenceField label="resources.employees.name" source="employee_id" reference="employees" link="show">
                                        <TextField source="name" />
                                    </ReferenceField>

                                </Labeled>
                            )}
                        </>
                    )} />
                    <ReferenceField source="transaction_id" reference="transactions" link="show" />
                    <DateField source="created_at" {...CONSTANTS.timeStampOptions} />
                    <ReferenceField source="registered_by" reference="employees" show="show">
                        <TextField source="name" />
                    </ReferenceField>
                </TabbedShowLayout.Tab>
                <DocumentTab target="payment_id" />
            </TabbedShowLayout>
        </Show>
    ) : <Forbidden {...props} />;
};

const PaymentFilter = [
    <ReferenceInput source="sector_id" reference="sectors" alwaysOn>
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <DateInput source="from_date" alwaysOn validate={maxValue(new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().substring(0, 10), "সর্বোচ্চ গতকালের তারিখ")} />, // Maximum value must be yesterday
    <DateInput source="to_date" alwaysOn validate={maxValue(new Date().toISOString().substring(0, 10), "সর্বোচ্চ আজকের তারিখ")} />, // Maximum value must be today
    <SelectInput source="status" choices={CONSTANTS.paymentStatus} translate='yes' defaultValue={'due'} />,
    
]
const WaiveButton = () => {
    const { selectedIds } = useListContext();
    const [waiveIntent, setWaiveIntent] = React.useState(false);
    const [waiving, setWaiving] = React.useState(false);
    const [waiveError, setWaiveError] = React.useState(false);
    const [waiveSuccess, setWaiveSuccess] = React.useState(false);
    const [waiveMessage, setWaiveMessage] = React.useState('');
    const [waiveAmount, setWaiveAmount] = React.useState(0);
    const [waiveReason, setWaiveReason] = React.useState('');
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const unselectAll = useUnselectAll('payments');
    const translate = useTranslate('bn');
    const waive = () => {
        setWaiving(true);
        dataProvider.updateMany('payments', { ids: selectedIds, data: { status: 'waived', amount: waiveAmount, note: waiveReason } })
            .then(({ data }) => {
                setWaiving(false);
                setWaiveIntent(false);
                unselectAll('payments');
                notify('resources.payments.messages.waived_success', { type: 'success' });
            })
            .catch(({ message }) => {
                notify(message, { type: 'error' });
                setWaiving(false);
            })
    }
    return (
        waiveIntent ? (<Confirm
            title={translate('resources.payments.action.waive')}
            content={<>
                <Typography variant="body1" component="p">
                    {translate('resources.payments.messages.waive_confirm_message')}
                </Typography>
                <MuiTextInput
                    label={translate('resources.payments.fields.waive_reason')}
                    value={waiveReason}
                    onChange={e => setWaiveReason(e.target.value)}
                    error={waiveReason === ''}
                    multiline
                />
            </>}
            confirmColor="warning"
            open={true}
            onConfirm={waive}
            onClose={() => setWaiveIntent(false)}
        />) : <Button
            label="resources.payments.action.waive"
            onClick={e => {
                console.log('waive')
                e.stopPropagation();
                setWaiveIntent(true);
            }}
            disabled={selectedIds.length === 0 || waiving}
            color="error"
            variant='contained'
        ><MoneySlashIcon variant="contained" /></Button>
    )

}
const PaymentBulkActionButtons = () => (
    <>
        <WaiveButton />
        <ExportButton />
    </>
);
export const PaymentList = ({ permissions, ...props }) => {
    const Payments = props => {
        const { data, total, setPerPage } = useListContext();
        //setPerPage(1000);
        const translate = useTranslate('bn');
        let totalAmount = 0, totalCount = total;
        if (data && Object.keys(data).length) {
            for (let id in data) {
                if (typeof data[id].total_amount == 'undefined')
                    continue;
                totalAmount = data[id].total_amount;
                break;
            }
        }
        return (
            <>
                <Box>
                    <Typography
                        style={{
                            margin: '10px',
                            padding: '10px',
                            background: totalAmount < 0 ? RED[50] : GREEN[50],
                            color: totalAmount < 0 ? RED[900] : GREEN[900],
                        }}
                        variant="h6"
                        gutterBottom
                    >
                        {translate('resources.payments.fields.total_amount')} : <b>{totalAmount.toLocaleString('bn-BD', { style: 'currency', currency: 'BDT', minimumFractionDigits: 0 })}</b>
                    </Typography>
                </Box>
                <Datagrid bulkActionButtons={<PaymentBulkActionButtons />} rowClick="show">
                    <NumberField source="id" {...CONSTANTS.locales} />
                    <ChipField source="status" />
                    <ReferenceField source="sector_id" reference="sectors" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="student_id" reference="students" link={false}>
                        <TextField source="name_bn" />
                    </ReferenceField>
                    <ReferenceField source="employee_id" reference="employees" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    {/* {permissions && permissions.payments.READ && (
                        <ReferenceField source="student_id" reference="students" link="show" >
                            <TextField source="name_bn" />
                        </ReferenceField>
                    )}
                    {permissions && permissions.payments.READ && (
                        <ReferenceField source="staff_id" reference="staffs" link='show'>
                            <TextField source="name_bn" />
                        </ReferenceField>
                    )}
                    {permissions && permissions.payments.READ && (
                        <ReferenceField source="registered_by" reference="staffs" link="show">
                            <TextField source="name_bn" />
                        </ReferenceField>
                    )} */}
                    {1 && <NumberField source="amount" {...CONSTANTS.currencyOptions} />}
                    <DateField source="created_at" {...CONSTANTS.timeStampOptions} />
                    {/* <FunctionField render={record => record && ExecuteButton(record)} /> */}

                </Datagrid>
            </>

        );
    }
    return (
        1 ? <List perPage={10000} filters={PaymentFilter}>
            <Payments />
        </List> : <Forbidden />
    );
}