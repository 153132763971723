import { useEffect, useState, lazy, Suspense } from 'react';
import { AdminContext, AdminUI, Resource, usePermissions, defaultTheme, AppBar, Button, Authenticated, useAuthenticated, Confirm, ListGuesser, useLogout } from 'react-admin';
import { QueryClient } from "react-query";
import { useNavigate } from 'react-router-dom'
import authProvider from './providers/authProvider.ts';
import translationProvider from './providers/translationProvider';
/*************************** ICONS ************************************** */
import PeopleIcon from '@mui/icons-material/People';
import CampaignIcon from '@mui/icons-material/Campaign';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InventoryIcon from '@mui/icons-material/Inventory';
import CartIcon from '@mui/icons-material/ShoppingCart';
import LogIcon from '@mui/icons-material/History';
import BadgeIcon from '@mui/icons-material/Badge';
import SchoolIcon from '@mui/icons-material/School';
import PaymentIcon from "./assets/hand-money-icon.jsx"
import SectorIcon from "./assets/pocket-money.jsx"
import ExamIcon from "@mui/icons-material/HistoryEdu"
import DescriptionIcon from '@mui/icons-material/Description';
import ConfirmIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import indigo from '@mui/material/colors/indigo';
import green from '@mui/material/colors/green';
import pink from '@mui/material/colors/pink';
import red from '@mui/material/colors/red';
import InstitutionIcon from '@mui/icons-material/Business';
/****************************************************************** */
import { StudentCreate, StudentEdit, StudentShow, StudentList } from './components/students';
import { EmployeeList, EmployeeShow, EmployeeCreate, EmployeeEdit } from './components/employees/employees.js';
import { NoticesList, NoticesShow, NoticesEdit, NoticesCreate } from './components/notices';
import { SectorList, SectorCreate, SectorEdit, SectorShow } from './components/sectors';
import { TransactionList, TransactionCreate, TransactionEdit, TransactionShow } from './components/transactions';
import { DocumentList, DocumentShow, DocumentCreate, DocumentEdit } from './components/documents/documents.js';
import { LogList, LogShow, LogCreate, LogEdit } from './components/logs';
import { ClassList, ClassCreate, ClassEdit, ClassShow } from './components/classes';
import { ExamsList, ExamsCreate, ExamsShow, ExamsEdit } from './components/exams';
import { PaymentShow, PaymentList, PaymentCreate_ } from './components/payments/payments.js';
import { SubjectEdit, SubjectList, SubjectShow } from './components/subjects/index.js';
import Dashboard from './components/dashboard';
import CustomLayout from './Layout';
import theme from './theme.js';
import './App.css';
import dataProvider from './providers/dataProvider';
import { InstitutionCreate, InstitutionEdit, InstitutionList, InstitutionShow } from './components/institutions';
import LoadingPanel from './components/LoadingPage.jsx';
import CONSTANTS from './components/Constants.ts';
import strings from './content.json';
const WelcomePage = lazy(() => import('./components/WelcomePage.jsx'));
const Printer = lazy(() => import('./Printer.jsx'));
const Redirect = ({ to }) => {
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Redirecting to", to)
    navigate(to);
  }, [to, navigate]);
  console.log("Redirecting to", to)
  return null;

}
const FilterResources = (props) => {
  const { isLoading: permissionLoading, permissions } = usePermissions();
  const resources = [];
  if (!permissionLoading)
    if (false && !permissions) {
      resources.push(
        <Resource key="loading" name="loading" list={() => <Redirect key="redirect" to="/login" />} />
      )
    }
    else {
      if (permissions?.students?.list)
        resources.push(<Resource
          key="students"
          name="students"
          list={StudentList}
          {...(permissions?.students?.viewOfficial || permissions?.students?.viewPersonal ? { show: StudentShow } : {})}
          {...(permissions?.students?.updateOfficial || permissions?.students?.updatePersonal ? { edit: StudentEdit } : {})}
          {...(permissions?.students?.create ? { create: StudentCreate } : {})}
          icon={SchoolIcon}
          recordRepresentation={record => `${record?.name_bn} (#${record?.id})`}
        />);
      if (permissions?.employees.list)
        resources.push(<Resource
          key="employees"
          name="employees"
          list={EmployeeList}
          icon={BadgeIcon}
          {...(permissions?.employees.viewOfficial || permissions?.employees.viewPersonal ? { show: EmployeeShow } : {})}
          show={EmployeeShow}
          create={EmployeeCreate}
          edit={EmployeeEdit}
          recordRepresentation={record => `${record?.name} (#${record?.id})`}
        />);

      if (permissions?.notices?.list)
        resources.push(<Resource
          key="notices"
          name="notices"
          list={NoticesList}
          show={NoticesShow}
          create={NoticesCreate}
          icon={CampaignIcon}
          hasEdit={false}
        />);

      if (permissions?.sectors?.list)
        resources.push(<Resource
          key="sectors"
          name="sectors"
          list={SectorList}
          edit={SectorEdit}
          create={SectorCreate}
          show={SectorShow}
          icon={SectorIcon}
          recordRepresentation={record => `${record?.name} (#${record?.id})`}
        />);
      if (permissions?.transactions?.list)
        resources.push(<Resource
          key="transactions"
          name="transactions"
          icon={AttachMoneyIcon}
          list={TransactionList}
          show={TransactionShow}
          create={TransactionCreate}
          hasEdit={false}
        />);
      if (permissions?.payments?.list)
        resources.push(<Resource
          key="payments"
          name="payments"
          list={PaymentList}
          show={PaymentShow}
          create={PaymentCreate_}
          icon={PaymentIcon}

        />);
      if (permissions?.classes?.list)
        resources.push(<Resource
          key="classes"
          name="classes"
          list={ClassList}
          edit={ClassEdit}
          show={ClassShow}
          create={ClassCreate}
          icon={PeopleIcon}
          recordRepresentation={record => `${record?.name}`}
        />);
      if (permissions?.exams?.list)
        resources.push(<Resource
          key="exams"
          name="exams"
          list={ExamsList}
          show={ExamsShow}
          create={ExamsCreate}
          edit={ExamsEdit}
          icon={ExamIcon}
          recordRepresentation={record => `${record?.name}`}
        />);
      if (permissions?.documents?.list)
        resources.push(<Resource
          key="documents"
          name="documents"
          icon={DescriptionIcon}
          list={DocumentList}
          show={DocumentShow}
          create={DocumentCreate}
          edit={DocumentEdit}
        />);
      if (permissions?.institutions?.list) {
        resources.push(<Resource
          key="institutions"
          name="institutions"
          create={permissions?.institutions?.create && InstitutionCreate}
          list={permissions?.institutions?.list && InstitutionList}
          show={permissions?.institutions?.show && InstitutionShow}
          edit={permissions?.institutions?.updateOthers && InstitutionEdit}
          icon={InstitutionIcon}
          recordRepresentation={record => `${record?.name}`}
        />);
      }
      if (permissions?.logs?.list)
        resources.push(<Resource
          key="logs"
          name="logs"
          list={LogList}
          show={permissions?.logs?.show && LogShow}
          icon={LogIcon}
          hasCreate={false}
          hasEdit={false}
        />);



      // Hidden Resources
      resources.push(<Resource
        name="subjects"
        key="subjects"
        recordRepresentation={record => `${record?.name}`}
        show={SubjectShow}
        edit={SubjectEdit}
      />)
      resources.push(
        <Resource
          name="sessions"
          key="sessions"
          recordRepresentation={record => `${record?.name} (#${record.id})`} />
      )
    }
  return (
    <AdminUI
      disableTelemetry
      ready={WelcomePage}
      dashboard={permissions && Dashboard}
      layout={CustomLayout}
    >
      {permissionLoading ? <Resource list={LoadingPanel} name="" /> : resources}
    </AdminUI>
  );
}
function Admin({ setPrintComponent, setStyle }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000 * 60 * 5,
      },
    },
  });
  dataProvider.setPrintComponent = (component, style = '') => {
    setStyle(style);
    setPrintComponent(component);
    return Promise.resolve()
  }
  return (
    <AdminContext
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={translationProvider}
      queryClient={queryClient}
      theme={theme}
    >
      <FilterResources />
    </AdminContext>
  );
}
const InstallPrompt = ({ setInstallPrompt, installPrompt }) => {
  const onClose = (e, v = { outcome: 'dismissed' }) => {
    localStorage.setItem(CONSTANTS.INSTALLED_KEY, JSON.stringify(v));
    setInstallPrompt(null);
  }
  return <Confirm
    sx={{
      '& .MuiDialog-paper': {
        backgroundColor: green[100],
      }
    }}
    isOpen={true}
    title={strings.installPromptTitle}
    content={<p>{strings.installPromptContent}
      &nbsp; <a href={strings.installPromptDetailsLink}>(বিস্তারিত)</a>
    </p>}
    confirm={strings.installPromptInstall}
    cancel={strings.installPromptCancel}
    onConfirm={() => {
      const promptEvent = installPrompt;
      if (!promptEvent) {
        // The deferred prompt isn't available.
        return;
      }
      // Show the install prompt.
      promptEvent.prompt();
      // Log the result
      promptEvent.userChoice.then((result) => onClose(null, result));
    }}
    ConfirmIcon={ConfirmIcon}
    CancelIcon={CancelIcon}
    autoFocus
    onClose={onClose}
    confirmColor='success'

  />
}
function App() {
  const [installPrompt, setInstallPrompt] = useState(null);
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      if (localStorage.getItem(CONSTANTS.INSTALLED_KEY)) return;
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      e.preventDefault();
      // Save the event because you'll need to trigger it later.
      setInstallPrompt(e);
    });
  }, []);
  const [printComponent, setPrintComponent] = useState(null);
  const [style, setStyle] = useState('');
  return (
    <Suspense fallback={<LoadingPanel />}>
      {installPrompt && <InstallPrompt installPrompt={installPrompt} setInstallPrompt={setInstallPrompt} />}
      {printComponent ? <Printer component={printComponent} setPrintComponent={setPrintComponent} style={style} /> : <Admin setPrintComponent={setPrintComponent} setStyle={setStyle} />}
    </Suspense>
  );
}
export default App;
